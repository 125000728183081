import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';

import TextareaAutosize from 'react-textarea-autosize';
import Chip from '@material-ui/core/Chip';

import Divider from '@material-ui/core/Divider';

import PdfIcon from '@material-ui/icons/GetApp';

import { makeStyles } from '@material-ui/core/styles';

import { useFirebase } from 'providers/firebase/FirebaseContext';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        // width: '100vw',
        height: '581px',
        margin: '0 0 0 0',
        padding: '0 0 0 0',
        justifyContent: 'space-between',
        flexGrow: 12,
        // minWidth: '683px'
    },
    padding: {
        backgroundColor: '#000000',
        width: '60px',
        height: '100%',
        flexGrow: 10
    },
    column: {
        height: '100%',
        backgroundColor: '#000000',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 5,
        flexShrink: 1,
        maxWidth: '663px'
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexShrink: 0,
        paddingBottom: '6px'
    },
    titleLabel: {
        fontFamily: 'Bebas Neue',
        fontSize: '32px',
        color: '#FFFFFF',
        marginLeft: '22px',
        lineHeight: '1.1'
    },
    titleStack: {
        display: 'flex',
        flexDirection: 'column'
    },
    applyButton: {
        display: 'inline-block',
        textAlign: 'center',
        marginRight: '15px',
        marginLeft: '5px',
        color: theme.palette.primary.main,
        outlineColor: theme.palette.primary.main,
        outlineWidth: '1px',
        outlineStyle: 'solid',
        borderColor: 'transparent',
        width: '128px',
        // height: '34px',
        fontSize: '16px',
        lineHeight: '1.5'
    },
    contactButton: {
        display: 'inline-block',
        textAlign: 'center',
        marginLeft: '22px',
        marginTop: '13px',
        marginBottom: '21px',
        color: theme.palette.primary.main,
        outlineColor: theme.palette.primary.main,
        outlineWidth: '1px',
        outlineStyle: 'solid',
        borderColor: 'transparent',
        width: '175px',
        height: '43px',
        fontSize: '16px',
        lineHeight: '2'
    },
    divider: {
        backgroundColor: '#FFFFFF'
    },
    sublabel: {
        color: '#FFFFFF',
        fontFamily: 'Bebas Neue',
        fontSize: '14px',
        padding: '17.5px 16px 17.5px 0',
        marginLeft: '31.5px'
    },
    tagRow: {
        marginLeft: '25.5px',
    },
    chip: {
        // minWidth: '101px',
        justifyContent: 'center',
        fontSize: '14px',
        fontWeight: 'normal',
        marginRight: '7px',
        marginBottom: '6px',
        borderRadius: 15,
        height: 26,
        paddingTop: '2px'
    },
    descriptionLabel: {
        fontSize: '12px',
        marginLeft: '31.5px',
        paddingRight: '12px',
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        backgroundColor: '#000000',
        flexGrow: 10,
        borderStyle: 'none',
        resize: 'none',
        overflow: 'hidden',
        outline: 'none',
        width: '92%'
    },
    resumeLabel: {
        textAlign: 'center',
        color: '#FFFFFF'
    },
    resumeContainer: {
        paddingRight: '8px'
    },
    pdfIcon: {
        width: '39px',
        height: '48px',
        color: theme.palette.primary.main,
        fontSize: '40px'
    },
    iconButton: {
        padding: '0 8px 8px 8px'
    },
    scrollableArea: {
        overflow: 'auto',
        overflowX: 'hidden',
        maxWidth: '663px',
        paddingBottom: '34px',
        width: '100%',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#585858',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            borderRadius: '1em',
            outline: '1px solid #585858'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#363636',
            borderRadius: '1em'
        }

    }
}));


export default function PreviewPane(props) {

    const classes = useStyles();
    const firebase = useFirebase();

    const trackEmailClick = () => {
        firebase.trackAnalyticsEvent('email_athlete', { athlete: props.input.email });
    };

    const trackApply = () => {
        firebase.trackAnalyticsEvent('apply_clicked', { jobLink: props.input.jobLink });
    };

    return (
        <div className={classes.container} style={props.style}>
            <div className={classes.column}>
                <div className={classes.titleRow}>
                    <div className={classes.titleStack}>
                        <div className={classes.titleLabel} style={{ marginTop: '16px' }}>
                            {props.type === 'jobPost' ? props.input.jobTitle : [props.input.firstName, props.input.lastName].join(' ')}
                        </div>
                        {props.type === 'jobPost' ? (
                            <div className={classes.titleLabel}>
                                {props.input.companyName}
                            </div>
                        ) : (
                                <Button href={'mailto:' + props.input.email} target="_blank" className={classes.applyButton} style={{ marginTop: 15, marginBottom: 20, marginLeft: 22 }} onClick={trackEmailClick}>
                                    CONTACT NOW
                                </Button>
                            )}
                    </div>

                    {props.type === 'jobPost' ? (
                        <Button href={props.input.jobLink} target="_blank" className={classes.applyButton} onClick={trackApply}>
                            APPLY NOW
                        </Button>
                    ) : (
                            <div className={classes.resumeContainer}
                                style={(props.resumeFilePaths !== undefined && props.resumeFilePaths.length > 0) ? {} : { display: 'none' }}>
                                <div className={classes.resumeLabel}>RESUME</div>
                                <IconButton
                                    className={classes.iconButton}
                                    onClick={props.handleDownloadClicked}
                                >
                                    <PdfIcon className={classes.pdfIcon} />
                                </IconButton>
                            </div>

                        )}
                </div>
                <Divider classes={{ root: classes.divider }} variant="middle" />

                <div className={classes.scrollableArea}>
                    {props.input.tagData.categories.slice().reverse().map((category, index) => {
                        return (
                            <>
                                {index > 0 && (
                                    <Divider classes={{ root: classes.divider }} variant="middle" style={{ marginTop: '15.5px' }} />
                                )}
                                <InputLabel className={classes.sublabel} > {category.name} </InputLabel>
                                {category.subCategories.map(subcategory => {
                                    return (
                                        <div className={classes.tagRow}>
                                            {subcategory.tags.map(tag => {
                                                return (
                                                    <Chip className={classes.chip}
                                                        label={tag.displayString}
                                                        color="primary"
                                                    />
                                                )
                                            })
                                            }
                                        </div>
                                    );
                                })}
                            </>
                        );

                    })}
                    {props.type === 'athleteProfile' && (<div style={{ height: '10px' }} />)}

                    {props.type === 'jobPost' &&
                        (
                            <>
                                <Divider classes={{ root: classes.divider }} variant="middle" style={{ marginTop: '15.5px' }} />
                                <InputLabel className={classes.sublabel} >JOB DESCRIPTION </InputLabel>
                                <TextareaAutosize disabled className={classes.descriptionLabel}>
                                    {props.input.jobDescription}
                                </TextareaAutosize>
                            </>
                        )}
                </div>
            </div>
            {/*<div className={classes.padding}></div>*/}
        </div>
    );
}