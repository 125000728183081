import React from 'react';
import ReactPlayer from 'react-player';

import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom';

import PostPreview from 'components/post/PostPreview';

import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

import { useFirebase } from 'providers/firebase/FirebaseContext';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    position: 'relative',
    margin: 10,
  },
  nameContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    color: '#edd814',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    textTransform: 'uppercase',
    padding: theme.spacing(1, 2),
  },
  blankVideo: {
    backgroundColor: '#262626',
    width: '370px',
    height: '209px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  videoCover: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  profileModal: {
    display: 'flex',
    alignItems: 'center',
  },
  modalContainer: {
    position: 'relative',
    backgroundColor: '#000000',
    padding: '20px',
    maxWidth: '90%',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    borderStyle: 'solid'
  },
  baseModal: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#000000',
    justifyItems: 'space-even',
    alignItems: 'center',
    padding: '0 67px'
  },
  topBar: {
    display: 'flex',
    flexDirection: 'row'
  },
  inputLabel: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    lineHeight: '1.19',
    marginBottom: '9px',
    paddingTop: '21px'
  },
  closeButton: {
    float: 'right'
  },
  label: {
    lineHeight: '1.1',
    paddingTop: '3px'
  }
}));

function JobVideo({ job, employerView, setJob }) {
  const classes = useStyles();
  const firebase = useFirebase();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {

    if (employerView) {
      setJob(job);
      history.push('/postJob');
    } else {
      firebase.trackAnalyticsEvent('view_job', { 'job_id': job.id });
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const transformedData = {
    jobTitle: job.position,
    jobDescription: job.jobDescription,
    jobCompanyVideo: job.companyVideoURL,
    jobManagerVideo: job.hiringManagerVideoURL,
    jobLink: job.applicationLink,
    tagData: job.tagData,
    companyName: job.companyName,
  };

  function getVideoData() {
    var videoData = [];
    if (transformedData.jobCompanyVideo && transformedData.jobCompanyVideo.length) {
      videoData.push({ videoURL: transformedData.jobCompanyVideo, videoType: 'company_video' });
    }
    if (transformedData.jobManagerVideo && transformedData.jobManagerVideo.length) {
      videoData.push({ videoURL: transformedData.jobManagerVideo, videoType: 'hiring_manager_video' });
    }
    return videoData;
  }

  return (
      <>
        <Modal
            open={open}
            className={classes.profileModal}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableEnforceFocus
            disableRestoreFocus
            disableAutoFocus
        >
          <div className={classes.modalContainer}>
            <div className={classes.closeContainer}>
              <IconButton className={classes.closeButton} onClick={handleClose}>
                <Close fontSize='24px' style={{ fill: '#FFFFFF' }} />
              </IconButton>
            </div>
            <PostPreview
                input={transformedData}
                videos={getVideoData()}
                type="jobPost"
                containerStyle={{ width: 'unset' }}
                paddingStyle={{ minWidth: 'unset' }}
                modal={true}
            />
          </div>
        </Modal>
        <div className={classes.videoContainer}>
          {(job.companyVideoURL || job.hiringManagerVideoURL) ? (
              <ReactPlayer url={job.companyVideoURL || job.hiringManagerVideoURL}
                           controls={true}
                           light={true}
                           width='370px'
                           height='209px'
              />
          ) : (
              <div className={classes.blankVideo}>
                <Typography variant="h6">video unavailable</Typography>
              </div>
          )}

          <div className={classes.videoCover} onClick={handleOpen}></div>
              <div className={classes.nameContainer} onClick={handleOpen}>
                <Typography variant="h6" className={classes.label}>
                  <Link underline="none">{job.position}, {job.companyName}</Link>
                </Typography>
              </div>
        </div>
      </>

  );
}

export default JobVideo;