import React, { useState } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import './index.css';

import SignInContainer from 'components/sign-in/SignInContainer';
import SignUpContainer from 'components/sign-up/SignUpContainer';
import ProfileContainer from 'components/profile/ProfileContainer';
import CreatePostContainer from 'components/create-post/CreatePostContainer';
import Dashboard from 'components/dashboard/Dashboard';
import Media from 'components/media/Media';
import Events from 'components/events/Events';
import GamePlan from 'components/gameplan/GamePlan';
import Legal from 'components/legal/Legal';
import SellPage from 'components/sell-page/SellPage';

import NavBar from 'components/navbar/NavBar';
import PhotoBackground from 'components/photo-background/PhotoBackground';
import BottomBar from 'components/bottom-bar/BottomBar';
import Unverified from 'components/modals/Unverified';

import PrivateRoute from 'components/private-route/PrivateRoute';
import UnauthRoute from 'components/unauth-route/UnauthRoute';
import VerifiedRoute from "components/private-route/VerifiedRoute";


import CssBaseline from '@material-ui/core/CssBaseline';
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';
import theme from './Theme.js';

import GoogleFontLoader from 'react-google-font-loader';
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "@material-ui/lab/Alert/Alert";

import NavigationAnalyticsTracker from 'providers/firebase/NavigationAnalyticsTracker';

import UserProvider from 'providers/user/UserProvider';

import {
    isBrowser,
    isMobileOnly
} from "react-device-detect";

function App() {
    const [alert, setAlert] = useState({ message: "" });

    // error UI
    // eslint-disable-next-line no-unused-vars
    const [open, setOpen] = React.useState(false);

    const [modalOpen, setModalOpen] = React.useState(false);

    const [job, setJob] = React.useState({});

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({ ...alert, message: "" });
        setOpen(false);
    };

    return (
        <>
            { isBrowser && (
                <UserProvider>
                    <GoogleFontLoader fonts={[
                        {
                            font: 'Bebas Neue',
                            weights: [400],
                        },
                        {
                            font: 'Montserrat',
                            weights: [400, 500, 800, 900]
                        }
                    ]} />
                    <MuiThemeProvider theme={theme}>
                        <Snackbar anchorOrigin={{ vertical: "top", horizontal: 'center' }} style={{ marginTop: '100px' }}
                                  open={alert.message.length > 0} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity={alert.severity} style={{ fontSize: '16pt' }}>
                                {alert.message}
                            </Alert>
                        </Snackbar>
                        <Snackbar anchorOrigin={{ vertical: "top", horizontal: 'center' }} style={{ marginTop: '100px' }}
                                  open={alert.message.length > 0} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity={alert.severity} style={{ fontSize: '16pt' }}>
                                {alert.message}
                            </Alert>
                        </Snackbar>
                        <CssBaseline />
                        <Router>
                            <NavigationAnalyticsTracker/>
                            <NavBar setModalOpen={(open) => {setModalOpen(open)}} setJob={setJob}/>
                            <Switch>
                                <UnauthRoute path="/signup">
                                    <SignUpContainer setAlert={setAlert} />
                                </UnauthRoute>
                                <UnauthRoute path="/login">
                                    <SignInContainer setAlert={setAlert} />
                                </UnauthRoute>
                                <VerifiedRoute path="/postJob">
                                    <CreatePostContainer job={job} setAlert={setAlert} />
                                </VerifiedRoute>
                                <PrivateRoute path="/profile">
                                    <ProfileContainer setAlert={setAlert} setModalOpen={(open) => {setModalOpen(open)}} />
                                </PrivateRoute>
                                <Route path="/dashboard">
                                    <Dashboard setJob={setJob}/>
                                </Route>
                                <Route path="/media">
                                    <Media />
                                </Route>
                                <Route path="/events">
                                    <Events />
                                </Route>
                                <Route path="/gameplan">
                                    <GamePlan />
                                </Route>
                                <Route path="/legal">
                                    <Legal />
                                </Route>
                                <Route path="/">
                                    <PhotoBackground />
                                </Route>
                            </Switch>
                            <BottomBar />
                        </Router>
                        <Unverified modalOpen={modalOpen} setModalOpen={(open) => {setModalOpen(open)}}/>
                    </MuiThemeProvider>
                </UserProvider>
            )}
            {isMobileOnly && (
                <>
                    <GoogleFontLoader fonts={[
                        {
                            font: 'Bebas Neue',
                            weights: [400],
                        },
                        {
                            font: 'Montserrat',
                            weights: [600, 800]
                        }
                    ]} />
                    <MuiThemeProvider theme={theme}>
                        <SellPage></SellPage>
                    </MuiThemeProvider>
                </>
            )}
        </>

    );
}

export default App;