/* eslint-disable no-unused-vars */
import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';

import TagSelectionPane from 'components/tags/TagSelectionPane';

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";

import { cloneDeep } from "lodash"

const useStyles = makeStyles((theme) => ({
    baseModal: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#000000',
        justifyItems: 'space-even',
        alignItems: 'center',
        padding: '0 67px'
    },
    topBar: {
        display: 'flex',
        flexDirection: 'row'
    },
    inputLabel: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        lineHeight: '1.19',
        marginBottom: '9px',
        paddingTop: '21px'
    },
    closeButton: {
        float: 'right'
    },
    tagPaneContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    applyButton: {
        display: 'inline-block',
        textAlign: 'center',
        margin: '30px 0',
        color: theme.palette.primary.main,
        outlineColor: theme.palette.primary.main,
        outlineWidth: '1px',
        outlineStyle: 'solid',
        borderColor: 'transparent',
        width: '158px',
        height: '34px',
        fontSize: '16pt',
        lineHeight: '1.2'
    },
}));


export default function TagSelector(props) {

    const initialTagSelectionState = props.jobTagData;

    const [selectedTags, setSelectedTags] = React.useState(initialTagSelectionState);

    const handleTagClick = (e, categoryId, categoryIndex, subcategoryId, subcategoryIndex, tagId) => {

        let updatedSelectedTags = cloneDeep(selectedTags);

        if (updatedSelectedTags[categoryId] === undefined) {
            updatedSelectedTags[categoryId] = { [subcategoryId]: [] }
        } else if (updatedSelectedTags[categoryId][subcategoryId] === undefined) {
            updatedSelectedTags[categoryId][subcategoryId] = [];
        }


        let isSelected = updatedSelectedTags[categoryId][subcategoryId].includes(tagId);

        console.log(props.fullTagList);
        const selectionType = props.fullTagList.categories[categoryIndex].subCategories[subcategoryIndex].selectionType;


        if (selectionType === 'single') {
            if (isSelected) {
                delete updatedSelectedTags[categoryId][subcategoryId];
            } else {
                updatedSelectedTags[categoryId][subcategoryId] = [tagId];
            }
        } else if (selectionType === 'category-single') {
            // a single selection is allowed across all subcategories
            if (isSelected) {
                delete updatedSelectedTags[categoryId];
            } else {
                updatedSelectedTags[categoryId][subcategoryId] = [tagId];

                for (let [key, value] of Object.entries(updatedSelectedTags[categoryId])) {
                    if (key !== subcategoryId) {
                        delete updatedSelectedTags[categoryId][key]
                    }
                }
            }
        } else {
            // multi-select
            if (isSelected) {
                if (updatedSelectedTags[categoryId][subcategoryId].length > 1) {
                    updatedSelectedTags[categoryId][subcategoryId].splice(updatedSelectedTags[categoryId][subcategoryId].indexOf(tagId), 1);
                } else {
                    delete updatedSelectedTags[categoryId][subcategoryId]
                }
            } else {
                updatedSelectedTags[categoryId][subcategoryId].push(tagId);
            }
        }

        setSelectedTags(updatedSelectedTags);
    };

    const applyTags = () => {
        props.applyTags(selectedTags);
    };

    const classes = useStyles();

    const tagSelectionPanes = props.fullTagList.categories.map((category, index) =>
        <TagSelectionPane category={category}
            title={index === 0 ? 'Add tags' : ''}
            jobTagData={selectedTags[category.id]}
            handleTagClick={(event, subcategoryId, subcategoryIndex, tagId) => handleTagClick(event, category.id, index, subcategoryId, subcategoryIndex, tagId)}
        />
    );


    return (
        <div style={props.style} className={classes.baseModal}>
            <div className={classes.tagPaneContainer} >
                {tagSelectionPanes}
            </div>
            <Button className={classes.applyButton}
                size="medium"
                onClick={applyTags}
            >
                Apply
            </Button>
        </div>
    );
}