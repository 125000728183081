import React, { useEffect, useState } from "react";
import { useFirebase } from 'providers/firebase/FirebaseContext';
import { UserContext } from 'providers/user/UserContext';

const initialState = {
  loading: true,
  data: null
};

export default function UserProvider({ children }) {
  const firebase = useFirebase();
  const [user, setUser] = useState(initialState);

  useEffect(() => {
    let removeProfileListener = () => { };
    const profileObserver = {
      next: (snapshot) => {
        console.log('Profile updated ', snapshot.data());

        let userData = snapshot.data();

        // testing athlete
        if (userData != undefined && userData.email == 'test@newfiltering.com') {
          userData.type = 'athlete';
        }

        setUser({
          ...user,
          loading: false,
          data: userData
        });
        if (snapshot && snapshot.exists && snapshot.id) {
          firebase.setUserId(snapshot.id);
        }
      },
      error: (e) => {
        setUser({
          loading: false,
          data: null
        });
      }
    };

    const removeAuthChangeListener = firebase.auth.onAuthStateChanged(currentUser => {
      if (currentUser) {
        firebase.analytics.setUserId(currentUser.uid);
        firebase.trackAnalyticsEvent('login');
        console.log('fetching user ');
        removeProfileListener = firebase.useProfile(profileObserver);
      } else {
        removeProfileListener();
        setUser({
          loading: false,
          data: null
        });
      }
    });

    return () => {
      removeProfileListener();
      removeAuthChangeListener();
    }
  }, [firebase]);

  return (
    <>
      <UserContext.Provider value={user}>
        {children}
      </UserContext.Provider>
    </>
  );
}
