import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';



const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#000000',
        borderColor: theme.palette.primary.main,
        border: '1px solid'
    },
    text: {
        color: '#FFFFFF'
    }
}));

export default function SimpleDialog(props) {
    const classes = useStyles();
    const { onClose, onConfirm, open } = props;


    return (
        <Dialog PaperProps={{className: classes.root}} onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle className={classes.text} id="simple-dialog-title">Confirm Job Removal</DialogTitle>
            <DialogContent>

                <DialogContentText className={classes.text} id="alert-dialog-description">
                    Are you sure you want to remove this job?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    Remove Job
                </Button>
            </DialogActions>
        </Dialog>
    );
}