import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import Check from '@material-ui/icons/Check';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tagPane: {
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: '1px',
        width: '287px',
        height: '521px',
        margin: '0 21.5px 0 21.5px',
        padding: '26px 32px'
    },
    inputLabel: {
        color: theme.palette.primary.main
    },
    subcategoryList: {
        maxHeight: '473px',  // 522 - 49
        overflow: 'auto',
        scrollbarWidth: '0px',
        overflowX: 'hidden'
    },
    listItem: {
        color: theme.palette.primary.main,
        padding: '2px 0',
    },
    expandIndicator: {
        color: theme.palette.primary.main
    },
    tagListItem: {
        color: '#FFFFFF',
        padding: '0 3px 0 0px',
        height: 32
    },
    tagListItemPrimary: {
        fontFamily: 'Montserrat',
        fontSize: '10px',
        fontWeight: '600'
    },
    checkbox: {
        color: theme.palette.primary.main,
        marginRight: '-6px'
    },
    uncheckedIcon: {
        width: '12px',
        height: '12px',
        border: '1px solid',
        borderColor: theme.palette.primary.main
    },
    checkedIcon: {
        width: '12px',
        height: '12px',
        border: '1px solid',
        borderColor: theme.palette.primary.main
    },
    check: {
        color: theme.palette.primary.main,
        fontSize: '12px',
        position: 'absolute',
        top: '9px',
        left: '9px'
    },
    titleLabel: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        lineHeight: '1.19',
        marginBottom: '9px',
        paddingTop: '21px'
    },
    titleContainer: {
        marginLeft: '21.5px'
    }
}));

export default function TagContainer(props) {
    const category = props.category;

    const initialCollapseState = category.subCategories.reduce((state, subcategory) => {
        return {
            ...state,
            [subcategory.id]: true  // everything expanded initially
        }
    }, {});

    const [collapseState, setCollapseState] = React.useState(initialCollapseState)

    const handleSubcategoryClick  = (e, subcategoryId) => {
        const collapsed = collapseState[subcategoryId];
        setCollapseState({
            ...collapseState,
            [subcategoryId]: !collapsed
        });
    };


    function isTagSelected(subcategoryId, tagId) {
        return props.jobTagData !== undefined && props.jobTagData[subcategoryId] !== undefined && props.jobTagData[subcategoryId].includes(tagId);
    }


    const classes = useStyles();

    return (
        <div>
            {props.title.length > 0 && (
                <div className={classes.titleContainer}>
                    <InputLabel className={classes.titleLabel} >
                        {props.title}
                    </InputLabel>
                </div>
            )}
        <div className={classes.tagPane}>
            <InputLabel className={classes.inputLabel}>{category.name}</InputLabel>
            <List disablePadding
                  className={classes.subcategoryList}
            >
                {category.subCategories.map((subcategory, index) => {
                        return (
                            <>
                                <ListItem button disableRipple onClick={(event) => handleSubcategoryClick(event, subcategory.id)} key={subcategory.id} className={classes.listItem}>
                                    <ListItemText primary={subcategory.name} className={classes.listItem}/>
                                    {collapseState[subcategory.id] ? <ExpandLess className={classes.expandIndicator}/> : <ExpandMore className={classes.expandIndicator}/>}
                                </ListItem>
                                <Collapse in={collapseState[subcategory.id]}>
                                    <List component="div" disablePadding style={{marginLeft:'25px'}}>
                                        {subcategory.tags.map((tag) => {
                                            return (
                                                <ListItem button
                                                          disableRipple
                                                          onClick={(event) => props.handleTagClick(event, subcategory.id, index, tag.id)}
                                                          className={classes.tagListItem}
                                                          key={tag.id}>
                                                    <ListItemText classes={{primary:classes.tagListItemPrimary}}
                                                                  primary={tag.displayString}/>
                                                    <Checkbox edge="end"
                                                              disableRipple
                                                              checked={isTagSelected(subcategory.id, tag.id)}
                                                              className={classes.checkbox}
                                                              icon={(<div className={classes.uncheckedIcon}/> )}
                                                              checkedIcon={
                                                                  (<div className={classes.checkedIcon} >
                                                                      <Check className={classes.check}/>
                                                                  </div>)}
                                                    />
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Collapse>
                            </>
                        )
                    }
                )}
            </List>
        </div>
            </div>
    );
}