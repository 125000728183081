import React from 'react';
import ReactPlayer from 'react-player';

import {Modal} from "@material-ui/core";

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import PostPreview from 'components/post/PostPreview';

import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

import { useFirebase } from 'providers/firebase/FirebaseContext';

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    position: 'relative',
    margin: 10,
  },
  nameContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    color: '#edd814',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    textTransform: 'uppercase',
    padding: theme.spacing(1, 2),
  },
  blankVideo: {
    backgroundColor: '#262626',
    width: '370px',
    height: '209px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  videoCover: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  profileModal: {
    display: 'flex',
    alignItems: 'center',
  },
  modalContainer: {
    position: 'relative',
    backgroundColor: '#000000',
    padding: '20px',
    maxWidth: '90%',
    borderColor: theme.palette.primary.main,
    borderWidth: '1px',
    borderStyle: 'solid'
  },
  baseModal: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#000000',
    justifyItems: 'space-even',
    alignItems: 'center',
    padding: '0 67px',
    width: '100%'
  },
  topBar: {
    display: 'flex',
    flexDirection: 'row'
  },
  inputLabel: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    lineHeight: '1.19',
    marginBottom: '9px',
    paddingTop: '21px'
  },
  closeButton: {
    float: 'right'
  },
  label: {
    lineHeight: '1.1',
    paddingTop: '3px'
  }
}));

function CandidateVideo({ athlete }) {
  const classes = useStyles();
  const firebase = useFirebase();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    firebase.trackAnalyticsEvent('view_profile', {'profile_id': athlete.id});
  };

  const handleClose = () => {
    setOpen(false);
  };

  const transformedData = {
    firstName: athlete.name ? athlete.name.first : '',
    lastName: athlete.name ? athlete.name.last : '',
    jobDescription: athlete.description,
    jobCompanyVideo: athlete.proudestTeamMomentVideoURL,
    jobManagerVideo: athlete.careerHighlightVideoURL,
    email: athlete.email,
    tagData: athlete.tagData,
  };


  function getVideoData() {
    var videoData = [];
    if (transformedData.jobCompanyVideo && transformedData.jobCompanyVideo.length) {
      videoData.push({ videoURL: transformedData.jobCompanyVideo, videoType: 'proudest_team_moment' });
    }
    if (transformedData.jobManagerVideo && transformedData.jobManagerVideo.length) {
      videoData.push({ videoURL: transformedData.jobManagerVideo, videoType: 'career_highlight' });
    }
    return videoData;
  }


  return (
      <>
        <Modal
            open={open}
            className={classes.profileModal}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableEnforceFocus
            disableRestoreFocus
            disableAutoFocus
        >
          <div className={classes.modalContainer}>
            <div className={classes.closeContainer}>
              <IconButton className={classes.closeButton} onClick={handleClose}>
                <Close fontSize='24px' style={{ fill: '#FFFFFF' }} />
              </IconButton>
            </div>
            <PostPreview
                input={transformedData}
                videos={getVideoData()}
                type="athletePost"
                containerStyle={{width: 'unset'}}
                paddingStyle={{minWidth: 'unset'}}
                resumeFilePaths={athlete.resumeFilePaths}
                modal={true}
            />
          </div>
        </Modal>
        <div className={classes.videoContainer}>
          {(athlete.proudestTeamMomentVideoURL || athlete.careerHighlightVideoURL) ? (
              <ReactPlayer url={athlete.proudestTeamMomentVideoURL || athlete.careerHighlightVideoURL}
                           controls={true}
                           light={true}
                           width='370px'
                           height='209px'
              />
          ) : (
              <div className={classes.blankVideo}>
                <Typography variant="h6">video unavailable</Typography>
              </div>
          )}

          <div className={classes.videoCover} onClick={handleOpen}></div>

          <div className={classes.nameContainer}>
            <Typography variant="h6" className={classes.label} >{athlete.name ? (
                `${athlete.name.first} ${athlete.name.last}`
            ) : (
                'unknown'
            )}</Typography>
          </div>
        </div>
      </>
  );
}

export default CandidateVideo;