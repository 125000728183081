import React from 'react';

import PreviewPane from 'components/preview-pane/PreviewPane';

import ReactPlayer from 'react-player';

import { useFirebase } from 'providers/firebase/FirebaseContext';

import { makeStyles } from '@material-ui/core/styles';

import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        width: '100%',
        // height: '581px',
        margin: '0 0 0 0',
        padding: '0 0 0 0',
        maxWidth: '100%',
        justifyContent: 'stretch',
        backgroundColor: 'black',
    },
    modalContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        width: '100vw',
        height: '581px',
        margin: '0 0 0 0',
        padding: '0 0 0 0',
        maxWidth: '100vw',
        justifyContent: 'center',
        backgroundColor: 'black',
    },
    padding: {
        backgroundColor: '#000000',
        // width: '135px',
        height: '100%',
        flexBasis: '135px',
        flexGrow: 10,
        flexShrink: 10
    },
    modalPadding: {
        backgroundColor: '#000000',
        width: '135px',
        height: '100%',
        minWidth: '135px',
        flexShrink: '1000'
    },
    singleVideoPlayer: {
        // height: '100%',
        backgroundColor: '#000000',
        position: 'absolute',
        top: 0,
        left: 0,
        maxHeight: '581px'
    },
    doubleVideoPlayer: {
        // height: '100%',
        backgroundColor: '#000000',
        top: 0,
        left: 0,
        // maxHeight: '581px'
    },
    modalDoubleVideoPlayer: {
        // height: '100%',
        backgroundColor: '#000000',
        top: 0,
        left: 0,
        maxHeight: '581px'
    },
    videoStack: {
        flexDirection: 'column',
        display: 'flex',
        minWidth: '25%',
        // width: '655px',
    },
    modalVideoStack: {
        flexDirection: 'column',
        display: 'flex',
        minWidth: '25vw',
        // width: '655px',
    },
    playerWrapper: {
        minWidth: '54.4%',
        width: '1083px',
        position: 'relative'
    },
    modalPlayerWrapper: {
        minWidth: '54.4vw',
        width: '1083px',
        position: 'relative',
        flexShrink: 10
    }
}));


export default function PostPreview(props) {

    const firebase = useFirebase();

    const handleDownloadClicked = () => {

        firebase.trackAnalyticsEvent('resume_download', { athlete: props.input.email });

        if (props.resumeFilePaths !== undefined) {
            firebase.downloadFiles(props.resumeFilePaths, [props.input.firstName, props.input.lastName, 'resume'].join('_')).then(files => {
                files.forEach(file => {
                    console.log('downloading file', file);
                    saveAs(file);
                });
            });
        };
    };

    const classes = useStyles();

    function trackVideoAnalytics(videoType, videoLink) {
        firebase.trackAnalyticsEvent('view_video', { 'video_type': videoType, 'video_link': videoLink });
    }

    return (
        <div className={props.modal ? classes.modalContainer : classes.container} style={props.containerStyle}>
            <div className={props.modal ? classes.modalPadding : classes.padding} style={props.paddingStyle}></div>
            {props.videos.length > 0 ?
                (props.videos.length > 1) ? (
                    <div className={props.modal ? classes.modalVideoStack : classes.videoStack}>
                        <ReactPlayer url={props.videos[0].videoURL}
                            controls={true}
                            onPlay={() => { trackVideoAnalytics(props.videos[0].videoType, props.videos[0].videoURL) }}
                            className={props.modal ? classes.modalDoubleVideoPlayer : classes.doubleVideoPlayer}
                            height='50%'
                            width='100%'
                        />
                        <ReactPlayer url={props.videos[1].videoURL}
                            controls={true}
                            onPlay={() => { trackVideoAnalytics(props.videos[0].videoType, props.videos[0].videoURL) }}
                            className={props.modal ? classes.modalDoubleVideoPlayer : classes.doubleVideoPlayer}
                            height='50%'
                            width='100%'
                            style={{ marginTop: '24px' }}
                        />
                    </div>
                ) : (
                        <div className={props.modal ? classes.modalPlayerWrapper : classes.playerWrapper} style={props.paddingStyle}>
                            <ReactPlayer url={props.videos[0].videoURL}
                                controls={true}
                                onPlay={() => { trackVideoAnalytics(props.videos[0].videoType, props.videos[0].videoURL) }}
                                className={props.modal ? classes.modalSingleVideoPlayer : classes.singleVideoPlayer}
                                height='100%'
                                width='100%'
                            />
                        </div>

                    ) : (
                    <div className={props.modal ? classes.modalVideoStack : classes.videoStack} />
                )}


            <PreviewPane input={props.input}
                style={{ marginLeft: '18.3px', flexShrink: 1 }}
                type={props.type}
                handleDownloadClicked={handleDownloadClicked}
                resumeFilePaths={props.resumeFilePaths}
            />
            {props.modal || (
                <div className={classes.padding} style={props.paddingStyle}></div>
            )}
        </div>
    );


}