import React, { useState } from 'react';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';

import Check from '@material-ui/icons/Check';

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  tagPane: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: '1px',
    width: '100%',
    marginTop: 10,
    padding: '26px 32px',
    overflowY: 'scroll',
    maxHeight: '90%'
  },
  listItem: {
    color: theme.palette.primary.main,
    padding: '0',
    margin: '4px 0 0 0'
  },
  expandIndicator: {
    color: theme.palette.primary.main
  },
  tagListItem: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '10px',
    fontWeight: '500',
    margin: '0',
    padding: '0 3px 0 0px',
    height: 32,
    lineHeight: '3'
  },
  tags: {
    paddingLeft: 25
  },
  filterTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'white',
    fontSize: '16px',
    fontFamily: 'Montserrat',
    fontWeight: '500'
  },
  resetFilterButton: {
    color: '#edd814',
    fontSize: '11px',
    fontFamily: 'Montserrat',
    width: '84px',
    borderRadius: '0',
    border: '1px solid #edd814',
    textTransform: 'none'
  },
  checkbox: {
    color: theme.palette.primary.main,
    marginRight: '-6px',
    marginBottom: '4px'
  },
  uncheckedIcon: {
    width: '12px',
    height: '12px',
    border: '1px solid',
    borderColor: theme.palette.primary.main
  },
  checkedIcon: {
    width: '12px',
    height: '12px',
    border: '1px solid',
    borderColor: theme.palette.primary.main
  },
  check: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    position: 'absolute',
    top: '9px',
    left: '9px'
  }
}));

function DashboardFilters(props) {
  const classes = useStyles();
  const [selectedTags, setSelectedTags] = useState({});
  const tagData = props.data;

  const subcategoriesList = tagData.categories.flatMap(category => category.subCategories);

  const handleTagClick = (subCategoryId, tagId) => {
    const tagsForSubcategory = selectedTags[subCategoryId] ?? [];
    const tagIndex = tagsForSubcategory.indexOf(tagId);
    if (tagIndex >= 0) {
      tagsForSubcategory.splice(tagIndex, 1)
    } else {
      tagsForSubcategory.push(tagId);
    }

    const newTags = {
      ...selectedTags,
      [subCategoryId]: tagsForSubcategory
    };

    setSelectedTags(newTags);

    props.onTagsUpdated(newTags);
  };

  const isTagSelected = (subCategoryId, tagId) => {
    const tagsForSubcategory = selectedTags[subCategoryId];
    const tag = tagsForSubcategory && tagsForSubcategory.includes(tagId);
    return (tag ? true : false);
  };

  const initialCollapseState = subcategoriesList.reduce((initialState, subcategory) => {
    return {
      ...initialState,
      [subcategory.id]: false  // everything collapsed initially
    }
  }, {});

  const [collapseState, setCollapseState] = useState(initialCollapseState);
  const handleSubcategoryClick = (subCategoryId) => {
    const collapsed = collapseState[subCategoryId];
    setCollapseState({
      ...collapseState,
      [subCategoryId]: !collapsed
    });
  };

  const clearSelectedTags = () => {
    setSelectedTags({});
    props.onTagsUpdated({});
  };

  return (
    <>
      <div className={classes.filterTitle}>
        Filter by:
        <Button
          className={classes.resetFilterButton}
          onClick={clearSelectedTags}
        >Clear All</Button>
      </div>
      <div className={classes.tagPane}>
        {subcategoriesList.map(subcategory => (
          <div key={subcategory.id}>
            <ListItem button disableRipple className={classes.listItem} onClick={e => handleSubcategoryClick(subcategory.id)}>
              <ListItemText primary={subcategory.name} className={classes.listItem} style={collapseState[subcategory.id] ? { paddingBottom: '6px' } : {}} />
              {collapseState[subcategory.id] ? <ExpandLess className={classes.expandIndicator} /> : <ExpandMore className={classes.expandIndicator} />}
            </ListItem>
            <Collapse in={collapseState[subcategory.id]}>
              <List className={classes.tags} disablePadding>
                {subcategory.tags.map((tag, index) => (
                  <ListItem button
                    disableRipple
                    onClick={() => { handleTagClick(subcategory.id, tag.id) }}
                    className={classes.tagListItem}
                    key={tag.id}>
                    <ListItemText classes={{ primary: classes.tagListItem }}
                      primary={tag.displayString} />
                    <Checkbox edge="end"
                      disableRipple
                      checked={isTagSelected(subcategory.id, tag.id)}
                      className={classes.checkbox}
                      icon={(<div className={classes.uncheckedIcon} />)}
                      checkedIcon={
                        (<div className={classes.checkedIcon} >
                          <Check className={classes.check} />
                        </div>)}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        ))}
      </div>
    </>
  );
}

export default DashboardFilters;
