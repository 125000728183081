import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

import FootballSellImage from 'res/sell-page/football_sell.png';
import SwimmerSellImage from 'res/sell-page/swimmer_sell.png';
import BasketballSellImage from 'res/sell-page/basketball_sell.png';
import BBallGirlsSellImage from 'res/sell-page/basketball_girls_sell.png';
import LogoSellImage from 'res/sell-page/logo_sell.png';
import MediaSellImage from 'res/sell-page/media_sell.jpg';
import PhoneInLapSellImage from 'res/sell-page/phone_in_lap_sell.png';
import PhoneSellImage from 'res/sell-page/phone_sell.png';

import PlayCircleFilledIcon from '@material-ui/icons/PlayArrow';

import AppStoreButton from '../photo-background/AppStoreButton';

const useStyles = makeStyles((theme) => ({
    mainDiv: {
        backgroundColor: '#000000',
    },
    firstSellContainer: {
        position: 'relative',
    },
    firstSellTransparentBackground: {
        backgroundColor: 'rgba(0, 0, 0, 0.53)',
        position: 'absolute',
        bottom: '50px'
    },
    firstSellDownloadContainer: {
        textAlign: 'center',
        display: 'inline-block',
        width: '100%',
        margin: '0 auto',
    },
    firstSellDownloadButton: {
        textAlign: 'center',
        marginTop: '40px',
        marginBottom: '20px',
        marginLeft: '42px',
        color: theme.palette.primary.main,
        outlineColor: theme.palette.primary.main,
        outlineWidth: '1px',
        outlineStyle: 'solid',
        borderColor: 'transparent',
        width: '142px',
        height: '39px',
        fontSize: '16px',
        lineHeight: '1.5',
        float: 'left',
    },
    firstSellAppStoreButton: {
        textAlign: 'center',
        marginTop: '40px',
        // marginBottom: '20px',
        marginRight: '42px',
        float: 'right',
        // width: '50%',
    },
    image: {
        height: '100%',
        width: '100%',
        margin: '-4px 0'
    },
    logoImage: {
        height: '100%',
        width: '90%',
        paddingTop: '32px',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    transparentBackground: {
        backgroundColor: 'rgba(0, 0, 0, 0.53)'
    },
    textOnImage: {
        paddingTop: '30px',
        paddingLeft: '42px',
        paddingRight: '42px',
        fontFamily: 'Montserrat',
        fontSize: '18',
        fontWeight: 600,
        lineHeight: 1.2,
        textAlign: 'center',
        color: '#ffffff',
        variant: 'body1',
        whiteSpace: 'pre-line',
    },
    blackBar: {
        textAlign: 'center',
        backgroundColor: 'black',
        margin: '0px',
        maxWidth: 'none',
        flexDirection: 'row',
        position: 'relative',
    },
    downloadNowButton: {
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        color: theme.palette.primary.main,
        outlineColor: theme.palette.primary.main,
        outlineWidth: '1px',
        outlineStyle: 'solid',
        borderColor: 'transparent',
        width: '142px',
        height: '39px',
        fontSize: '16px',
        lineHeight: '1.5',
    },
    headingSubheadingDiv: {
        backgroundColor: '#ffffff',
        textAlign: 'center',
    },
    headingLabel: {
        fontFamily: 'Montserrat',
        paddingTop: '30px',
        fontSize: '26px',
        fontWeight: 800,
        lineHeight: 1.23,
        textAlign: 'center',
        color: '#000000',
    },
    subheadingLabel: {
        paddingTop: '30px',
        paddingBottom: '42px',
        paddingLeft: '42px',
        paddingRight: '42px',
        fontFamily: 'Montserrat',
        fontSize: '18',
        fontWeight: 600,
        lineHeight: 1.22,
        textAlign: 'center',
        color: '#000000',
        variant: 'body1',
        whiteSpace: 'pre-line',
    },
    blackBarYellowLabel: {
        fontFamily: 'Montserrat',
        paddingTop: '32px',
        paddingBottom: '32px',
        paddingLeft: '26px',
        paddingRight: '26px',
        fontSize: '17px',
        fontWeight: 600,
        lineHeight: 1.23,
        textAlign: 'center',
        color: theme.palette.primary.main,
    },
    mediaLabel: {
        fontFamily: 'Montserrat',
        paddingTop: '24px',
        paddingBottom: '24px',
        fontSize: '26px',
        fontWeight: 800,
        lineHeight: 1.23,
        textAlign: 'center',
        color: '#ffffff',
    },
    mediaInformationText: {
        fontFamily: 'Montserrat',
        paddingTop: '10px',
        paddingBottom: '20px',
        paddingLeft: '22px',
        paddingRight: '22px',
        fontSize: '15px',
        fontWeight: 800,
        lineHeight: 1.27,
        textAlign: 'left',
        color: '#000000',
    },
    mediaContainer: {
        position: 'relative',
        overflow: 'hidden',
    },
    playButtonIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: '#FFFFFF',
        fontSize: '32px',
        paddingLeft: '3px',
        paddingTop: '3px',
        paddingRight: '3px',
        paddingBottom: '3px',
        borderRadius: '50%',
        borderWidth: '0px',
        borderColor: 'white',
        transform: 'translate(-50%, -50%)',
        border: '0',
    }
}));

function SellPage() {

    const classes = useStyles();

    useEffect(() => { });

    return (
        <>
            <div className={classes.mainDiv}>
                <div className={classes.firstSellContainer}>
                    <img className={classes.image} src={FootballSellImage} alt="Football" />
                    <div className={classes.blackBar} style={{ height: '100px' }}>
                        <div className={classes.firstSellTransparentBackground}>
                            <img className={classes.logoImage} src={LogoSellImage} alt="Logo" />
                            <div className={classes.firstSellDownloadContainer}>                                
                                <Button href='https://itunes.apple.com/in/app/athletes-launch/id1516106559?mt=8' className={classes.firstSellDownloadButton} size="medium">
                                    DOWNLOAD APP NOW
                                </Button>
                                <div className={classes.firstSellAppStoreButton}>
                                <AppStoreButton
                                    url='https://itunes.apple.com/in/app/athletes-launch/id1516106559?mt=8'
                                    width='117px'
                                    height='40px'
                                />
                                </div>
                            </div>

                            <Typography className={classes.textOnImage}>
                                {'A free video forward career platform to connect athletes and organizations who value a proven history of success.'}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div>
                    <img className={classes.image} src={SwimmerSellImage} alt="Swimmer" />
                    <div className={classes.transparentBackground} style={{ transform: 'translateY(-60px)' }}>
                        <Typography className={classes.textOnImage}>
                            {'Built specifically for College, Professional, and Olympic athletes to help make the next chapter a continuation of previous success in sports.'}
                        </Typography>
                    </div>
                </div>
                <div>
                    <img className={classes.image} src={BasketballSellImage} alt="Basketball" />
                    <div className={classes.transparentBackground} style={{ transform: 'translateY(-60px)' }}>
                        <Typography className={classes.textOnImage}>
                            {'Employers can explore candidates with attributes that can lead to success on the corporate playing field.'}
                        </Typography>
                    </div>
                </div>
                <div className={classes.headingSubheadingDiv}>
                    <Typography className={classes.headingLabel}>{'Employer Verification'}</Typography>
                    <Typography className={classes.subheadingLabel}>
                        {'We verify all employer information via website prior to usage of the athlete search and job posting.\n\nOnce verified, employers have free full usage of both the iOS and web versions.'}
                    </Typography>
                </div>
                <div className={classes.blackBar}>
                    <Typography className={classes.blackBarYellowLabel}>
                        {'Employers visit Athleteslaunch.com desktop to sign up now'}
                    </Typography>
                </div>
                <div>
                    <img className={classes.image} src={BBallGirlsSellImage} alt='Basketball girls' />
                </div>
                <div className={classes.headingSubheadingDiv}>
                    <Typography className={classes.headingLabel}>{'Attributes in Action'}</Typography>
                    <Typography className={classes.subheadingLabel}>
                        {'Athletes Launch offers a window for growing companies and hiring managers to actually watch candidates perform under pressure first hand in their previous career.\n\nThe value of team communication and positive attributes athletes develop can translate to success on the corporate playing field.'}
                    </Typography>
                </div>
                <div>
                    <img className={classes.image} src={PhoneInLapSellImage} alt='Phone in lap' />
                </div>
                <div className={classes.headingSubheadingDiv}>
                    <Typography className={classes.headingLabel}>{'Continuation Of Success'}</Typography>
                    <Typography className={classes.subheadingLabel}>
                        {'Athletes Launch can help you make the next chapter of your life a continuation of your previous success in sports.\n\nWatch videos from growing companies and from actual hiring managers who value your tenacity, teamwork and past dedication. Then select a path that resonates with you and move into action.'}
                    </Typography>
                </div>
                <div>
                    <img className={classes.image} src={PhoneSellImage} alt='Phone sell' />
                </div>
                <div className={classes.headingSubheadingDiv}>
                    <Typography className={classes.headingLabel}>{'Connection'}</Typography>
                    <Typography className={classes.subheadingLabel}>
                        {'World Champions, Olympians, Team Captains, and every level of college and professional athletes, whether currently hiring people or ready for transition into a company, can find common ground and connection through Athletes Launch.'}
                    </Typography>
                </div>
                <div className={classes.blackBar}>
                    <Typography className={classes.mediaLabel}>
                        {'Media'}
                    </Typography>
                </div>
                <div className={classes.mediaContainer}>
                    <img className={classes.image} src={MediaSellImage} alt='Media'/>
                    <PlayCircleFilledIcon className={classes.playButtonIcon} style={{ color: '#edd714', opacity: '0.70', backgroundColor: '#ffffff'}}/>
                </div>
                <div className={classes.headingSubheadingDiv}>
                    <Typography className={classes.mediaInformationText}>
                        {'Johnnie Bryant Utah Jazz Becoming NBA Coach - Bonus Footage - Coaching Beyond Limits'}
                    </Typography>
                </div>
                <div className={classes.blackBar}>
                    <Button href='https://itunes.apple.com/in/app/athletes-launch/id1516106559?mt=8' className={classes.downloadNowButton} size="medium">
                        DOWNLOAD APP NOW
                    </Button>
                </div>
            </div>
        </>
    );

}

export default SellPage;