import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import URLSearchParams from '@ungap/url-search-params'

import VisibilitySensor from 'react-visibility-sensor';

import { useFirebase } from 'providers/firebase/FirebaseContext';
import { useCurrentUser } from 'providers/user/UserContext';

import CandidateVideo from 'components/dashboard/CandidateVideo';
import JobVideo from 'components/dashboard/JobVideo';
import DashboardFilters from 'components/dashboard/DashboardFilters';

import EmployerTags from 'data/employer_tags.json';
import AthleteTags from 'data/athlete_tags.json';

import { intersection } from 'lodash';

import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    flexGrow: 1,
    flexBasis: '70vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'stretch',
    padding: 0,
    margin: 0,
    right: 0,
    overflow: 'scroll',
    left: 'auto',
    flexShrink: 0,
    width: '100%',
    maxWidth: 'none'
  },
  formTitle: {
    fontFamily: 'Montserrat',
    fontSize: '25pt',
    fontWeight: '800',
    justifyContent: 'left',
    alignSelf: 'flex-start',
    marginLeft: '120px'
  },
  dashboardGrid: {
    display: 'flex',
    justifyContent: 'left',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  blackBar: {
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'row',
    height: '60vh',
    justifyContent: 'center',
    width: '100%',
    // flexBasis: '700px'
  },
  dashboardFilters: {
    margin: '10px 0',
    top: '0',
    overfloxY: 'scroll',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  filterTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'white',
  },
  resetFilterButton: {
    color: '#edd814',
  },
  collapsiblePadding: {
    flexShrink: 10,
    flexBasis: '10vh'
  },
  gridContainer: {
    overflow: 'scroll',
    overflowX: 'hidden'
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Dashboard({ setJob }) {
  const classes = useStyles();
  const firebase = useFirebase();
  var { data } = useCurrentUser();
  const [athleteSnapshots, setAthleteSnapshots] = useState([]);
  const [jobSnapshots, setJobSnapshots] = useState([]);

  const [selectedTags, setSelectedTags] = useState({})
  const [filteredAthletes, setFilteredAthletes] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isGettingData, setIsGettingData] = useState(false);

  const employerJobsView = useQuery().get('viewJobs');


  if (!data) data = {type: 'athlete'};

  const tagData = (data.type === 'employer' && !employerJobsView) ? AthleteTags : EmployerTags;

  useEffect( () => {
    setHasMore(true);
    setAthleteSnapshots([]);
    setJobSnapshots([]);

  },[employerJobsView])


  useEffect(() => {
    if (employerJobsView) {
      setFilteredJobs(jobSnapshots.map(snapshot => snapshot.data()));
    } else {
      let updatedList = updateFilteredList();
      if (updatedList) {
        if (data.type === 'employer' && !employerJobsView) {
          setFilteredAthletes(updatedList);
        } else {
          setFilteredJobs(updatedList);
        }
      }
    }
  }, [athleteSnapshots, jobSnapshots, selectedTags]);

  useEffect(() => {
    if (!hasMore || isGettingData) {
      return;
    }
    if (data.type === 'employer') {
      if (filteredAthletes.length < 100) getMore();
    } else if (data.type === 'athlete') {
      if (filteredJobs.length < 100) getMore();
    }
  }, [filteredJobs, filteredAthletes]);

  const updateFilteredList = () => {
    console.log('updateFilteredList getting called with', selectedTags);
    console.log(athleteSnapshots);
    const items = (data.type === 'employer' && !employerJobsView) ? athleteSnapshots.map(snapshot => snapshot.data()) : jobSnapshots.map(snapshot => snapshot.data());
    if (!Object.values(selectedTags).length || !Object.keys(selectedTags).length) {
      if (data.type === 'employer' && !employerJobsView) {
        let validAthletes = items.filter((athlete) => {
          console.log('filtering athlete: ', athlete);
          return (athlete.name && athlete.name.first && athlete.name.first.length > 0 && athlete.tagData !== undefined);
        });
        setFilteredAthletes(validAthletes);
      } else {
        setFilteredJobs(items);
      }
      return;
    }

    let locationSubCategories = {};
    let nonLocationSubCategories = {};

    for (let [key, value] of Object.entries(selectedTags)) {
      if (key === 'europeCountries' || key === 'usaStates') {
        locationSubCategories[key] = value;
      } else {
        nonLocationSubCategories[key] = value;
      }
    }

    const filteredItems = [];

    items.forEach(item => {
      if (!item.tagData) {
        return;
      }

      let includeItem = true;
      let itemNonLocationSubCategories = item.tagData.categories.flatMap(category => category.subCategories)
        .filter(subCategory => (subCategory.id !== 'europeCountries' && subCategory.id !== 'usaStates'));

      for (let [subCategory, tags] of Object.entries(nonLocationSubCategories)) {
        let itemSubCategory = (itemNonLocationSubCategories.filter(itemSubCategory => itemSubCategory.id === subCategory))[0];
        if (tags.length > 0) {
          if (itemSubCategory && itemSubCategory.tags.length > 0) {
            const intersect = intersection(itemSubCategory.tags.flatMap(tag => tag.id), tags);
            includeItem = includeItem && (intersect.length > 0);
          } else {
            includeItem = false
          }
        }
      }

      const allLocationTags = [
        ...locationSubCategories['europeCountries'] ?? [],
        ...locationSubCategories['usaStates'] ?? []
      ]

      let itemLocationTags = item.tagData.categories.flatMap(category => category.subCategories)
        .filter(subCategory => (subCategory.id === 'europeCountries' || subCategory.id === 'usaStates'))
        .flatMap(subCategory => subCategory.tags)
        .flatMap(tag => tag.id);

      if (allLocationTags.length > 0) {
        const locationFound = allLocationTags.some(locationId => itemLocationTags.includes(locationId));
        includeItem = includeItem && locationFound;
      }

      if (includeItem) {
        filteredItems.push(item);
      }
    });

    return filteredItems;
  }

  const onTagsUpdated = (selectedTags) => {
    console.log('onTagsUpdated updated invoked with', selectedTags);
    setSelectedTags(selectedTags);
  };

  function addItems(items) {
    return (previousState, _) => {
      return [...previousState, ...items];
    }
  }

  const getMore = (startingAfter) => {
    // if we're getting data right now, then don't try to get more
    if (isGettingData || !hasMore) {
      return;
    }
    const type = data.type;
    var lastItem;
    if (!startingAfter) {
      if (
        ((type === 'employer' && !employerJobsView) && athleteSnapshots[athleteSnapshots.length - 1]) // if type is employer and we have the last athlete in the list
        || ((type === 'athlete' || employerJobsView) && jobSnapshots[jobSnapshots.length - 1]) // if type is athlete and we have the last job in the list
      ) {
        lastItem = (data.type === 'employer' && !employerJobsView) ? athleteSnapshots[athleteSnapshots.length - 1] : jobSnapshots[jobSnapshots.length - 1];
      }
    } else {
      lastItem = startingAfter
    }
    setIsGettingData(true);
    if (employerJobsView) {
      firebase.getDashboardContent('employerJobPosts', lastItem, data.organization)
        .then(result => {
          setIsGettingData(false);
          setHasMore(result.size === 100);
          setJobSnapshots(addItems(result.docs));
        })
        .catch(e => {
          setIsGettingData(false);
          console.log('dash', e);
        });

    } else {
      firebase.getDashboardContent(type, lastItem)
        .then(result => {
          setIsGettingData(false);
          setHasMore(result.size === 100);
          console.log('** results count: ', result.size)
          if (type === 'employer' && !employerJobsView) {
            setAthleteSnapshots(addItems(result.docs));
          } else {
            setJobSnapshots(addItems(result.docs));
          }
        })
        .catch(e => {
          setIsGettingData(false);
          console.log('dash', e)
        });
    }
  };

  return (
    <>
      <div className={classes.collapsiblePadding} />
      <div className={classes.formContainer}>
        {(data.type === 'employer' && !employerJobsView) && (
          <div className={classes.formTitle}>BROWSE ATHLETES</div>
        )}

        {(data.type === 'athlete') && (
          <div className={classes.formTitle}>BROWSE OPPORTUNITIES</div>
        )}

        {(employerJobsView) && (
          <div className={classes.formTitle}>VIEW {data.companyName.toUpperCase()} POSTS</div>
        )}

        <div className={classes.blackBar}>
          <div className={classes.collapsiblePadding} style={{ flexBasis: '5%', flexGrow: 1, flexShrink: 10 }} />
          {employerJobsView || (
            <div className={classes.dashboardFilters}>
              <div className={classes.collapsiblePadding} style={{ flexBasis: '10px', flexGrow: 2, flexShrink: 10 }} />
              <div style={{ maxHeight: '100%', flexGrow: '100' }}>
                <DashboardFilters data={tagData} onTagsUpdated={onTagsUpdated} />
              </div>
              <div className={classes.collapsiblePadding} style={{ flexBasis: '10px', flexGrow: 10, flexShrink: 10 }} />
            </div>
          )}
          <div className={classes.collapsiblePadding} style={{ flexBasis: '0%', flexGrow: 1, flexShrink: 1 }} />
          <Grid item xs={8} className={classes.gridContainer}>
            <div className={classes.dashboardGrid}>
              {(data.type === 'employer' && !employerJobsView) && filteredAthletes.map((ath, i) => (
                <>
                  {(i === filteredAthletes.length - 1) ? (<VisibilitySensor onChange={(isVisible => { if (isVisible) { getMore(); } })}>
                    <CandidateVideo key={`dashboard-athlete-${i}`} athlete={ath} />
                  </VisibilitySensor>
                  ) : (
                      <CandidateVideo key={`dashboard-athlete-${i}`} athlete={ath} />
                    )}
                </>
              ))}
              {(data.type === 'athlete' || employerJobsView) && filteredJobs.map((job, i) => (
                <>
                  {(i === filteredJobs.length - 1) ? (<VisibilitySensor onChange={(isVisible => { if (isVisible) getMore(); })}>
                    <JobVideo key={`dashboard-job-${i}`} job={job} employerView={employerJobsView} setJob={setJob} />
                  </VisibilitySensor>
                  ) : (
                      <JobVideo key={`dashboard-job-${i}`} job={job} employerView={employerJobsView} setJob={setJob} />
                    )}
                </>
              ))}
            </div>
          </Grid>
          <div className={classes.collapsiblePadding} style={{ flexBasis: '0%', flexGrow: 1, flexShrink: 10 }} />
        </div>
      </div>
      <div className={classes.collapsiblePadding} />
    </>
  );
}

export default Dashboard;