import React, {
    useState,
} from 'react';

import {makeStyles} from '@material-ui/core/styles';

import SignUpForm from 'components/sign-up/SignUp';

import Button from "@material-ui/core/Button";

import SidePhoto from 'components/photo-background/SidePhoto.js';

import { useFirebase } from 'providers/firebase/FirebaseContext';


const useStyles = makeStyles((theme) => ({
    formContainer: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        margin:0,
        right: 0,
        overflow: 'scroll',
        // paddingBottom: '100px',
        left: 'auto',
        flexShrink: 0,
        width: '100%',
        maxWidth: 'none'
    },
    formTitle: {
        fontFamily: 'Montserrat',
        fontSize: '33px',
        fontWeight: '800',
        justifyContent: 'left'
    },
    formSubtitle: {
        fontFamily: 'Montserrat',
        fontSize: '10px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        fontWeight: '400'
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // marginTop: '97px'
    },
    button: {
        width: '158px',
        height: '34px',
        border: 'solid 1px #000000',
        borderRadius: '0px',
        fontSize: '16px',
        lineHeight: '1.6'
    },
    collapsiblePadding: {
        flexShrink: 10,
        flexGrow: 10,
        flexBasis: '8vh'
    }
}));

export default function SignUpContainer(props) {
    const firebase = useFirebase();

    const error = {severity: 'error'};

    const handleSubmit = () => {
        // validation
        if (input.type !== 'athlete' && input.type !== 'employer') {
            return props.setAlert({...error, message: 'Please select a user type'});
        } else if (input.type === 'employer' && input.companyName.length === 0) {
            return props.setAlert({...error, message: 'Please enter your company name'});
        } else if (input.email.length === 0) {
            return props.setAlert({...error, message: 'Please provide an email address'});
        } else if (input.password.length < 6) {
            return props.setAlert({...error, message: 'Set a password with at least 6 characters'});
        } else {
            console.log('Signing up');
            /**
             * won't have to set user manually
             * as firebase sdk onAuthStateChanged will update UserProvider
             * which should propagate to components as needed
             * might have have to manually push to dashboard
             */
            firebase.signUp(input).then(r => {
                console.log("sign up", r);
            }).catch(e => {
                return props.setAlert({...error, message: firebase.errorMessageForAuthCode(e.code)});
            });
        }
    }


    const handleInputChange = (e) => {

        setInput({
            ...input,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    const [input, setInput] = useState({
        email: "",
        password: "",
        companyName: ""
    });

    const classes = useStyles();

    return (
        <>
            <div className={classes.formContainer}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    paddingLeft: '278px',
                    height: '67px',
                    width: '100%',
                    maxWidth: 'none'
                }}>
                    <div className={classes.formTitle}>SIGN UP</div>
                    <div className={classes.formSubtitle}>Please select Athlete or Company account type</div>
                </div>
                <div className={classes.collapsiblePadding} style={{flexBasis: '24px'}}/>
                <div style={{
                    backgroundColor: '#000000',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    maxHeight: '527px',
                    overflow: 'hidden',
                    justifyItems: 'stretch'
                    // minHeight: '440px',
                    // marginTop: '24.5px'
                }}>
                    <SignUpForm input={input} handleInputChange={handleInputChange}/>
                    <SidePhoto/>
                </div>
                <div className={classes.collapsiblePadding} style={{flexBasis: '4vh'}} />
                <div className={classes.buttonGroup}>
                    <Button className={classes.button} href="/">Cancel </Button>
                    <Button className={classes.button} onClick={handleSubmit} style={{
                        marginLeft: '37px',
                        color: '#FFFFFF',
                        backgroundColor: '#000000'
                    }}>Submit </Button>
                </div>
                <div className={classes.collapsiblePadding} style={{flexBasis: '4vh'}} />
            </div>
        </>
    );
}