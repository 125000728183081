import React from 'react';
import { useDropzone } from 'react-dropzone';

import InputLabel from '@material-ui/core/InputLabel';

import Plus from '@material-ui/icons/Add';
import Delete from '@material-ui/icons/Close';
import Description from '@material-ui/icons/Description';

import IconButton from '@material-ui/core/IconButton';

import { Document, Page, pdfjs } from 'react-pdf';

import { makeStyles } from '@material-ui/core/styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const useStyles = makeStyles((theme) => ({
    inputLabel: {
        color: theme.palette.primary.main,
        fontSize: '20px',
        lineHeight: '1.19',
        marginBottom: '9px'
    },
    plusButton: {
        padding: '0 0 0 0',
        border: '20px solid white'
    },
    plusIcon: {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        fontSize: '30px'
    },
    iconButton: {
        position: 'absolute',
        right: '-12px',
        top: '-12px',
        padding: '0 0 0 0'
    },
    deleteIcon: {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        fontSize: '24px',
        borderRadius: '12px',
    },
    pdfPage: {
        width: '100%',
        height: '100%'
    },
    defaultDocument: {
        backgroundColor: '#FFFFFF',
        alignItems: 'center'
    },
    defaultDocIcon: {
        color: '#000000',
        fontSize: '60px'
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        marginBottom: 16,
        marginRight: 16,
        height: 100,
        boxSizing: 'border-box',
        position: 'relative',
        justifyContent: 'center'
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
        alignItems: 'start'
    },
    img: {
        display: 'block',
        width: 'auto',
        height: 'auto',
        maxHeight: '100%'
    }
}));

export default function FileUploadField(props) {

    console.log(props.files);

    const classes = useStyles();

    const deleteFile = (index) => {
        let newFiles = [...props.files];
        newFiles.splice(index, 1);
        URL.revokeObjectURL(props.files[index].preview)
        props.setFiles(newFiles);
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        accept: 'image/*,.pdf',
        onDrop: acceptedFiles => {

            if (acceptedFiles.length + props.files.length > 3) return;

            props.setFiles(props.files.concat(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            }))));
        }
    });

    const thumbs = props.files.map((file, index) => (
        <div className={classes.thumb} key={file.name}>
            <div className={classes.thumbInner}>
                {file.type === 'application/pdf' ? (
                    <Document file={file.preview}>
                        <Page pageNumber={1}
                            className={classes.pdfPage}
                            width={100}
                            height={100}
                        />
                    </Document>
                ) : file.type.includes('image/') ? (
                    <img
                        src={file.preview}
                        className={classes.img}
                        alt='Resume'
                    />
                ) : (
                            <div className={classes.defaultDocument}>
                                <Description className={classes.defaultDocIcon} />
                            </div>
                        )
                }
            </div>
            <IconButton
                className={classes.iconButton}
                onClick={() => deleteFile(index)}
            >
                <Delete className={classes.deleteIcon} />
            </IconButton>
        </div>
    ));

    return (
        <div className={props.style}>
            <InputLabel className={classes.inputLabel}>
                {props.fieldName}
            </InputLabel>
            <IconButton
                className={classes.plusButton}
                onClick={open}
                disabled={props.files.length >= 3}
            >
                <Plus className={classes.plusIcon} />
            </IconButton>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
            </div>
            <aside className={classes.thumbsContainer}>
                {thumbs}
            </aside>
        </div>
    );
}