import React from 'react';

import FormField from 'components/form-fields/FormField';
import TagContainer from 'components/tags/TagContainer';

import VideoToolTip from 'components/create-post/VideoToolTip';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FileUploadField from "../form-fields/FileUploadField";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingLeft: '0px',
        margin: 'auto',
        // marginTop: '53px'
    },
    column: {
        top: '57px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '0px',
        marginRight: '33px',
        minWidth: '388px',
        flexGrow: 3
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: '10px',
        spacingInner: theme.spacing(8)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '100px'
    },
    radioLabel: {
        fontSize: '16pt'
    },
    collapsiblePadding: {
        flexShrink: 10,
        flexBasis: '278px'
    }
}));


export default function AthleteProfileForm(props) {

    const classes = useStyles();

    return (
        <Container component="main" className={classes.container}>
            <div className={classes.columnContainer}>
                <div className={classes.collapsiblePadding} />
                <div className={classes.column}>
                    <form className={classes.form} >
                        <FormField value={props.input.careerHighlightVideoURL}
                                   onChange={props.handleInputChange}
                                   name="careerHighlightVideoURL"
                                   placeholderText="Paste URL from Youtube, Vimeo, etc here"
                                   fieldName="Add Video Highlighting Your Athletic Career"
                                   style={{ marginTop: '0' }}
                                   inputStyle={{ width: '394px' }}
                                   endAdornment={( <VideoToolTip/> )}
                        />
                        <FormField value={props.input.proudestTeamMomentVideoURL}
                                   onChange={props.handleInputChange}
                                   name="proudestTeamMomentVideoURL"
                                   placeholderText="Paste URL from Youtube, Vimeo, etc here"
                                   fieldName="Add Video Highlighting Your Proudest Team Moment"
                                   style={{ marginTop: '50px' }}
                                   inputStyle={{ width: '394px' }}
                                   endAdornment={( <VideoToolTip/> )}
                        />
                        <TagContainer
                            fieldName="Add Tags"
                            allTags={props.allTags}
                            keyedTags={props.keyedTags}
                            onClick={props.handleTagClick}
                            style={{marginTop: '40px'}}
                        />
                    </form>
                </div>
                <div className={classes.column} style={{paddingLeft: '60px', minWidth: '420px'}}>
                    <form className={classes.form} >
                        <FileUploadField
                            fieldName="Add Resume"
                            style={{marginTop: '20px'}}
                            files={props.files}
                            setFiles={props.setFiles}
                        />
                    </form>
                </div>
            </div>
        </Container>
    );



}