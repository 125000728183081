import React, { useState } from 'react';

import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import IconButton from '@material-ui/core/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import FormField from 'components/form-fields/FormField';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    container: {
        // marginLeft: '278px',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
        // position: 'relative',
        paddingLeft: '0px',
        margin: '50px 0 0 0',
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    column: {
        top: '57px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '0px',
        // marginRight: '57px'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    radioButton: {
        color: theme.palette.primary.main,
        '&$checked': {
            color: '#000000'
        }
    },
    radioLabel: {
        fontSize: '16pt'
    },
    icon: {
        backgroundColor: '#FFFFFF',
        color: '#edd814'
    },
    iconContainer: {
        position: 'absolute',
        right: 5,
        bottom: -7,
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    collapsiblePadding: {
        flexBasis: '278px',
        flexShrink: 0,  // single column form - padding doesn't need to shrink
        minWidth: '10px'
    },
    disclaimer: {
        color: '#edd814',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        fontWeight: '400'
    }
}));


export default function SignIn(props) {

    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    if (props.input.type === 'athlete') {
        return (
            <Container component="main" className={classes.container}>
                <div className={classes.columnContainer}>
                    <div className={classes.collapsiblePadding} />
                    <div className={classes.column} >
                        <RadioGroup row onChange={props.handleInputChange} name="type">
                            <FormControlLabel className={classes.radioButton} classes={{label: classes.radioLabel}} value="athlete" control={<Radio className={classes.radioButton}/>} label='ATHLETE' labelPlacement='start' style={{paddingRight:'20px', marginLeft:'0px'}}/>
                            <FormControlLabel className={classes.radioButton} classes={{label: classes.radioLabel}} value="employer" control={<Radio className={classes.radioButton} />} label='COMPANY' labelPlacement='start'/>
                        </RadioGroup>
                        <FormField value={props.input.email} onChange={props.handleInputChange} name="email" placeholderText="Example@gmail.com" fieldName="What's your email address?" style={{marginTop:'20px'}} inputStyle={{width: '394px'}}/>
                        <FormField value={props.input.password}
                                   onChange={props.handleInputChange}
                                   name="password"
                                   fieldName="Create a password"
                                   style={{marginTop:'36px'}}
                                   type={showPassword ? '' : 'password'}
                                   inputStyle={{width: '394px'}}
                                   endAdornment={
                                       <IconButton
                                           aria-label="toggle password visibility"
                                           onClick={handleClickShowPassword}
                                           className={classes.iconContainer}
                                       >
                                           {showPassword ? <Visibility className={classes.icon} /> : <VisibilityOff className={classes.icon}/>}
                                       </IconButton>}
                        />
                    </div>
                </div>
            </Container>
        );
    } else {
        return (
            <Container component="main" className={classes.container}>
                <div className={classes.columnContainer}>
                    <div className={classes.collapsiblePadding} />
                    <div className={classes.column} >
                        <RadioGroup row onChange={props.handleInputChange} name="type">
                            <FormControlLabel className={classes.radioButton} classes={{label: classes.radioLabel}} value="athlete" control={<Radio className={classes.radioButton}/>} label='ATHLETE' labelPlacement='start' style={{paddingRight:'20px', marginLeft:'0px'}} inputStyle={{width: '394px'}}/>
                            <FormControlLabel className={classes.radioButton} classes={{label: classes.radioLabel}} value="employer" control={<Radio className={classes.radioButton} />} label='COMPANY' labelPlacement='start' inputStyle={{width: '394px'}}/>
                        </RadioGroup>
                        <FormField value={props.input.companyName} onChange={props.handleInputChange} name="companyName" placeholderText="Add company name" fieldName="Company Name" style={{marginTop:'20px'}} inputStyle={{width: '394px'}}/>
                        <FormField value={props.input.email} onChange={props.handleInputChange} name="email" placeholderText="Example@gmail.com" fieldName="What's your email address?" style={{marginTop:'36px'}} inputStyle={{width: '394px'}}/>
                        <FormField value={props.input.password}
                                   onChange={props.handleInputChange}
                                   name="password"
                                   fieldName="Create a password"
                                   style={{marginTop:'36px', position: 'relative'}}
                                   type={showPassword ? '' : 'password'}
                                   inputStyle={{width: '394px'}}
                                   endAdornment={
                                       <IconButton
                                           aria-label="toggle password visibility"
                                           onClick={handleClickShowPassword}
                                           className={classes.iconContainer}
                                       >
                                           {showPassword ? <Visibility className={classes.icon} /> : <VisibilityOff className={classes.icon}/>}
                                       </IconButton>}
                        />
                    </div>
                    <div className={classes.collapsiblePadding} style={{flexBasis: '100px', flexShrink: 1}}/>
                    <div className={classes.column} style={{justifyContent:'center'}} >
                        <div className={classes.disclaimer}>Company accounts will be verified prior to receiving access.</div>

                    </div>

                </div>
            </Container>
        );
    }


}