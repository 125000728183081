import React from 'react';

import FormField from 'components/form-fields/FormField';
import FormPhoneField from 'components/form-fields/FormPhoneField';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    container: {
        paddingLeft: '0px',
        margin: 'auto'
        // marginLeft: '278px',
        // marginTop: '53px'
    },
    column: {
        top: '57px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '0px',
        marginRight: '57px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: '10px',
        spacingInner: theme.spacing(8)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '100px'
    },
    radioLabel: {
        fontSize: '16pt'
    },
    goldButton: {
        color: '#edd814',
        fontSize: '11px',
        fontFamily: 'Montserrat',
        borderRadius: '0',
        border: '1px solid #edd814',
        textTransform: 'none'
    },
    collapsiblePadding: {
        flexShrink: 10,
        flexBasis: '278px'
    }
}));


export default function ProfileForm(props) {

    const classes = useStyles();

    if (props.type === 'athlete') {
        return (
            <Container component="main"  className={classes.container}>
                <div className={classes.columnContainer}>
                    <div className={classes.collapsiblePadding} />
                    <div className={classes.column}>
                        <form className={classes.form} >
                            <FormField value={props.input.firstName}
                                       onChange={props.handleInputChange}
                                       name="firstName"
                                       placeholderText="Add first name"
                                       fieldName="First Name*"
                                       inputStyle={{ width: '394px' }} />
                            <FormField value={props.input.email}
                                       onChange={props.handleInputChange}
                                       name="email"
                                       fieldName="Email*"
                                       style={{ marginTop: '36px' }}
                                       inputStyle={{ width: '394px' }}
                                       disabled />
                            <FormPhoneField value={props.input.phoneNumber}
                                            onChange={props.handlePhoneChange}
                                            name="phone"
                                            fieldName="Phone"
                                            style={{ marginTop: '36px' }}
                                            inputStyle={{ width: '394px' }} />

                        </form>
                    </div>
                    <div className={classes.column}>
                        <form className={classes.form} >
                            <FormField value={props.input.lastName}
                                       onChange={props.handleInputChange}
                                       name="lastName"
                                       placeholderText="Add last name"
                                       fieldName="Last Name*"
                                       inputStyle={{ width: '394px' }} />
                        </form>
                    </div>
                </div>
            </Container>
        );
    } else {
        return (
            <Container component="main"  className={classes.container}>
                <div className={classes.columnContainer}>
                    <div className={classes.collapsiblePadding} />
                    <div className={classes.column}>
                        <form className={classes.form} >
                            <FormField value={props.input.firstName}
                                       onChange={props.handleInputChange}
                                       name="firstName"
                                       placeholderText="Add first name"
                                       fieldName="First Name*"
                                       // style={{ marginTop: '20px' }}
                                       inputStyle={{ width: '394px' }}
                            />
                            <FormField value={props.input.email}
                                       onChange={props.handleInputChange}
                                       name="email"
                                       fieldName="Email*"
                                       style={{ marginTop: '36px' }}
                                       inputStyle={{ width: '394px' }}
                                       disabled
                            />
                            <FormPhoneField value={props.input.phoneNumber}
                                            onChange={props.handlePhoneChange}
                                            name="phone"
                                            fieldName="Phone*"
                                            style={{ marginTop: '36px' }}
                                            inputStyle={{ width: '394px' }}
                            />
                        </form>
                    </div>
                    <div className={classes.column}>
                        <form className={classes.form} >
                            <FormField value={props.input.lastName}
                                       onChange={props.handleInputChange}
                                       name="lastName"
                                       placeholderText="Add last name"
                                       fieldName="Last Name*"
                                       // style={{ marginTop: '20px' }}
                                       inputStyle={{ width: '394px' }}
                            />
                            <FormField value={props.input.companyName}
                                       onChange={props.handleInputChange}
                                       name="company"
                                       fieldName="Company*"
                                       style={{ marginTop: '36px' }}
                                       inputStyle={{ width: '394px' }}
                                       disabled
                            />
                            {props.verified && (
                                <Button className={classes.goldButton}
                                        onClick={props.handleViewJobs}
                                        style={{ marginTop: '58px', marginLeft: '148px' }}
                                >
                                    View Postings</Button>
                            )}
                        </form>
                    </div>
                </div>
            </Container>
        );
    }


}