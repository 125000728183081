import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { useCurrentUser } from 'providers/user/UserContext';

import basketball from 'res/webhome_bball.png';
import football from 'res/webhome_football.png';
import soccer from 'res/webhome_soccer.png';
import swimming from 'res/webhome_swim.png';

import Container from '@material-ui/core/Container';
import Button from "@material-ui/core/Button";
import AppStoreButton from './AppStoreButton';

const useStyles = makeStyles((theme) => ({
    backgroundContainer: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        height: '100%',
        padding: 0,
        margin: 0,
        right: 0,
        // position: 'fixed',
        left: 'auto',
        flexShrink: 0,
        width: '100%',
        maxWidth: 'none',
        justifyItems: 'stretch'
    },
    imagePane: {
        height: '100%',
        flexBasis: '25%',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        backgroundSize: 'cover'
        // backgroundSize: 'auto 100%'
    },
    grayBarContainer: {
        position: 'relative',
        marginTop: '-15%',
        // bottom: '70px',
        margin: '0px',
        paddingTop: '15%',
        width: '100%',
        minWidth: '940px',
        // minHeight: '220px'
    },
    grayBar: {

        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    innerGrayBar: {
        width: '100%',
        height: '100%',
        backgroundColor: '#00000080',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
    },
    label: {
        // width: '1229px',
        // height: '272px',
        fontFamily: 'Montserrat',
        fontWeight: '900',
        fontStretch: 'normal',
        fontStyle: 'normal',
        // lineHeight: '1.21',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#ffffff',
        width: '100%',
        height: '100%'
        // background: 'url(' + TitleText + ')',
    },
    buttonColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 1,
        padding: '10px 0'
    },
    collapsiblePadding: {
        flexBasis: '150px',
        flexShrink: 2
    },
    getStartedButton: {
        display: 'inline-block',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: '#000000',
        borderColor: 'transparent',
        width: '261px',
        flexBasis: '62px',
        flexShrink: 1,
        fontSize: '26px',
        lineHeight: '1.6',
        "&:hover": {
            backgroundColor: theme.palette.primary.main
        }
    },
    iosButtonLabel: {
        color: theme.palette.primary.main,
        fontSize: '12px',
        lineHeight: '1.19'
    }
}));

export default function PhotoBackground() {

    const classes = useStyles();
    const history = useHistory();

    const { data } = useCurrentUser();

    const handleGetStartedClick = () => {
        if (data) {
            history.push('/dashboard');
        } else {
            history.push('/signUp');
        }
    }

    return (
        <div style={{ position: 'relative', height: '100%' }}>
            <Container className={classes.backgroundContainer}>
                <div className={classes.imagePane} style={{ backgroundRepeat: 'no-repeat', marginRight: '2px', backgroundImage: "url(" + football + ")", backgroundPositionX: '50%' }} />
                {/*{showTwo && (*/}
                <div className={classes.imagePane} style={{ backgroundRepeat: 'no-repeat', marginRight: '2px', backgroundImage: "url(" + swimming + ")", backgroundPositionX: '60%' }} />
                {/*)}*/}
                {/*{showThree && (*/}
                <div className={classes.imagePane} style={{ backgroundRepeat: 'no-repeat', marginRight: '2px', backgroundImage: "url(" + basketball + ")", backgroundPositionX: '40%' }} />
                {/*)}*/}
                {/*{showFour && (*/}
                <div className={classes.imagePane} style={{ backgroundRepeat: 'no-repeat', backgroundImage: "url(" + soccer + ")", backgroundPositionX: '60%' }} />
                {/*)}*/}
            </Container>
            <div className={classes.grayBarContainer}>
                <div className={classes.grayBar}>
                    <div className={classes.innerGrayBar} >
                        <div className={classes.collapsiblePadding} style={{ flexBasis: '230px' }} />
                        <div className={classes.label} style={{ backgroundSize: 'contain', backgroundPosition: 'center', height: '100%', width: '100%', backgroundRepeat: 'no-repeat'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 750 60">
                                <defs>
                                    <style>
                                        @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900');
                                    </style>
                                </defs>
                                <text fill="#fff" font-family="Montserrat" font-size="40px" font-weight="900" transform="translate(2 28)">
                                    <tspan x="0" y="0">OPPORTUNITIES FOR ATHLETES</tspan>
                                    <tspan x="70px" dy="1.6em" fill="#edd814" font-family="Montserrat" font-size="22px" font-weight="900">JOBS, SPONSORSHIPS, INTERNSHIPS & MORE</tspan>
                                </text>
                            </svg>
                        </div>
                        <div className={classes.collapsiblePadding} style={{ flexBasis: '47px' }} />
                        <div className={classes.buttonColumn}>
                            <Button onClick={handleGetStartedClick} className={classes.getStartedButton} size="medium">
                                GET STARTED
                            </Button>
                            <div className={classes.collapsiblePadding} style={{ flexBasis: '45px' }} />
                            <div className={classes.iosButtonLabel} >
                                Check out jobs on the go
                            </div>

                            <AppStoreButton
                                url='https://itunes.apple.com/in/app/athletes-launch/id1516106559?mt=8'
                                width='137px'
                                height='46px'
                            />
                        </div>
                        <div className={classes.collapsiblePadding} style={{ flexBasis: '230px' }} />
                    </div>
                </div>
            </div>
        </div>
    );
}