import React from 'react';
import ReactPlayer from 'react-player';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    videoContainer: {
        position: 'relative',
        margin: 20,
        width: 260
    },
    title: {
        color: '#fcfbf9',
        marginTop: '5px',
        // maxHeight: 72,
        textOverflow: 'ellipsis',
        fontFamily: 'Montserrat',
        fontSize: '14pt',
        fontWeight: '800',
        overflow: 'hidden',
        lineHeight: 1.3,
        display: '-webkit-box',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical'
    }
}));

function MediaVideo({ media }) {
    const classes = useStyles();
    return (
        <div className={classes.videoContainer}>
            <ReactPlayer url={media.videoURL}
                         controls={true}
                         width='260px'
                         height='146px'
                         style={{borderWidth: '1px', borderColor: '#edd814', borderStyle: 'solid', padding: '5px'}}
            />
            <Typography className={classes.title} variant="h6">{media.title}</Typography>
        </div>
    )
}

export default MediaVideo;