import React from 'react';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import FormField from 'components/form-fields/FormField';

const useStyles = makeStyles((theme) => ({
    container: {
        // marginLeft: '278px',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
        // position: 'relative',
        paddingLeft: '0px',
        margin: 'auto'
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '150px'
    },
    column: {
        top: '57px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '0px',
        marginRight: '57px'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    radioButton: {
        color: theme.palette.primary.main,
        '&$checked': {
            color: '#000000'
        }
    },
    radioLabel: {
        fontSize: '16pt'
    },
    collapsiblePadding: {
        flexBasis: '278px',
        flexShrink: 0,  // single column form - padding doesn't need to shrink
        minWidth: '10px'
    }
}));


export default function SignInForm(props) {

    const classes = useStyles();

        return (
            <Container component="main" className={classes.container}>
                <div className={classes.columnContainer}>
                    <div className={classes.collapsiblePadding} />
                    <div className={classes.column} >
                        <FormField value={props.input.email} onChange={props.handleInputChange} name="email" placeholderText="Example@gmail.com" fieldName="Email address" style={{marginTop:'20px'}} inputStyle={{width: '394px'}}/>
                        <FormField value={props.input.password} onChange={props.handleInputChange} name="password" fieldName="Password" style={{marginTop:'36px'}} type='password' inputStyle={{width: '394px'}} />
                    </div>
                </div>
            </Container>
        );

}