import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';


import basketball from 'res/bball-side.png';
import swimming from 'res/swimming-side.png'
import football from 'res/football-side.png';

import { useMediaQuery } from 'react-responsive'


const useStyles = makeStyles((theme) => ({
    root: {
        flexBasis: '35vw',
        height: '27vw',
        objectFit: 'cover',
        minHeight: '440px',
        backgroundSize: 'cover',
        flexShrink: '1'
    }
}));

const photos = [
    {
        photo: basketball,
        xOffset: '40%'
    },
    {
        photo: swimming,
        xOffset: '60%'
    },
    {
        photo: football,
        xOffset: '50%'
    }
];

export default function SidePhoto() {

    const classes = useStyles();

    const showPhoto = useMediaQuery({
        query: '(min-width: 1000px)'
    });

    const [photo] = useState(photos[Math.floor(Math.random() * photos.length)])

    console.log(photo);

    return (
        <div className={classes.root} style={showPhoto ? {backgroundImage: "url(" + photo.photo + ")", backgroundPositionX: photo.xOffset} : {}}/>
    );
}