import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';

import Check from "@material-ui/icons/Check";
import Plus from '@material-ui/icons/Add';

import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    inputLabel: {
        color: theme.palette.primary.main,
        fontSize: '20px',
        lineHeight: '1.19',
        marginBottom: '9px'
    },
    tagChip: {
        minWidth: '101px',
        justifyContent: 'start',
        fontSize: '14px',
        fontWeight: 'normal',
        backgroundColor: '#FFFFFF',
        borderWidth: '1px',
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        marginRight: '10px',
        marginBottom: '12px',
        lineHeight: '1.5'
    },
    tagChipSelected: {
        minWidth: '101px',
        justifyContent: 'start',
        fontSize: '14px',
        fontWeight: 'normal',
        marginRight: '10px',
        marginBottom: '12px',
        lineHeight: '1.5'
    },
    selectedIcon: {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        borderRadius: '12px',
        fontSize: '18px'
    },
    unselectedIcon: {
        color: '#000000',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '12px',
        fontSize: '18px'
    }
}));


export default function TagContainer(props) {

    const classes = useStyles();

    const tagsSelectedInCategory = (categoryId) => {

        if (props.keyedTags[categoryId] === undefined) return false;

        let isSelected = false;
        for (let [, value] of Object.entries(props.keyedTags[categoryId])) {
            for (let [, tags] of Object.entries(value)) {
                if (tags.length > 0) isSelected = true;
            }
        }

        return isSelected;
    };

    const iconForTagState = (tagSelected) => {

        if(tagSelected)
            return (<Check className={classes.selectedIcon}/>);
        else
            return (<Plus className={classes.unselectedIcon}/>);
    };

    return (
        <div style={props.style}>
            <InputLabel className={classes.inputLabel}>
                {props.fieldName}
            </InputLabel>
            {props.allTags.categories.map((category, index) => {
                let tagsSelected = tagsSelectedInCategory(category.id);
                return (
                    <Chip label={category.name}
                          clickable
                          onClick={props.onClick}
                          name={category.id}
                          icon={iconForTagState(tagsSelected)}
                          className={tagsSelected ? classes.tagChipSelected : classes.tagChip }
                          color={tagsSelected ? "primary" : ""}
                    />
                )
            })}
        </div>
    );
}