import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";


const useStyles = makeStyles((theme) => ({
    modalContainer: {
        backgroundColor: '#000000',
        width: '44%',
        // height: '47vh',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'flex-start',
        flexDirection: 'column'
    },
    closeButton: {
        color: '#FFFFFF',
        paddingRight: '34.9px',
        paddingTop: '27.4px',
        fontSize: '19px',
        padding: '0',
        alignSelf: 'flex-end'
    },
    closeContainer: {
        alignSelf: 'flex-end'
    },
    modalLabel: {
        width: '23vw',
        color: '#fcfbf9',
        fontFamily: 'Montserrat',
        fontSize: '16px',
        paddingTop: '8.5vh',
        textAlign: 'left'
    },
    contactUsButton: {
        textAlign: 'center',
        margin: '33px 0',
        color: theme.palette.primary.main,
        outlineColor: theme.palette.primary.main,
        outlineWidth: '1px',
        outlineStyle: 'solid',
        borderColor: 'transparent',
        width: '158px',
        height: '34px',
        fontSize: '16px'
    }
}));

export default function Unverified(props) {

    const classes = useStyles();

    const handleClose = () => {
        props.setModalOpen(false);
    };

    return (
        <Modal open={props.modalOpen}
               onClose={() => { props.setModalOpen(false)}}
               style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
               disableAutoFocus
               disableEnforceFocus
        >
            <div className={classes.modalContainer}>
                <div className={classes.closeContainer}>
                    <IconButton className={classes.closeButton} onClick={handleClose}>
                        <Close fontSize='24px' style={{ fill: '#FFFFFF' }} />
                    </IconButton>
                </div>
                <div className={classes.modalLabel}>
                    Thank you for signing up on Athletes Launch.<br/><br/>
                    To protect the privacy of our athletes we verify all employers upon sign up. You will be contacted by customer support to approve your free access for viewing candidates and posting opportunities.
                    <br/><br/>
                    If you have any further questions please contact us at customersupport@athleteslaunch.com
                </div>
                <Button href='mailto:customersupport@athleteslaunch.com' className={classes.contactUsButton} size="medium">
                    CONTACT
                </Button>

            </div>
        </Modal>
    );
}