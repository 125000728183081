import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";

import SignInForm from 'components/sign-in/SignInForm.js';

import SidePhoto from 'components/photo-background/SidePhoto.js';

import { useFirebase } from 'providers/firebase/FirebaseContext';

const useStyles = makeStyles(() => ({
    formContainer: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        margin: 0,
        right: 0,
        overflow: 'scroll',
        // paddingBottom: '100px',
        left: 'auto',
        flexShrink: 0,
        width: '100%',
        maxWidth: 'none'
    },
    formTitle: {
        fontFamily: 'Montserrat',
        fontSize: '33px',
        fontWeight: '800',
        justifyContent: 'left'
    },
    formSubtitle: {
        fontFamily: 'Montserrat',
        fontSize: '10px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        fontWeight: '400'
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // marginTop: '97px'
    },
    button: {
        width: '158px',
        height: '34px',
        border: 'solid 1px #000000',
        borderRadius: '0px',
        fontSize: '16px',
        lineHeight: '1.6'
    },
    link: {
        textDecoration: 'none'
    },
    collapsiblePadding: {
        flexShrink: 10,
        flexGrow: 10,
        flexBasis: '8vh'
    }
}));

export default function SignInContainer(props) {
    const classes = useStyles();
    const firebase = useFirebase();

    const error = {severity: 'error'};

    const [input, setInput] = useState({
        email: "",
        password: ""
    });

    const handleLogin = () => {
        firebase.login(input.email, input.password)
            .catch(e => {
                console.log(e);
                props.setAlert({...error, message: firebase.errorMessageForAuthCode(e.code)});
            });
    };

    const handleSubmit = () => {
        if (input.email.length === 0) {
            return props.setAlert({...error, message: 'Please enter your email address.'});
        } else if (input.password.length === 0) {
            return props.setAlert({...error, message: 'Please enter your password.'});
        }

        handleLogin();
    }

    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    return (
        <>
            <div>
                <div className={classes.formContainer}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        paddingLeft: '278px',
                        height: '67px',
                        width: '100%',
                        maxWidth: 'none'
                    }}>
                        <div className={classes.formTitle}>SIGN IN</div>
                    </div>
                    <div className={classes.collapsiblePadding} style={{flexBasis: '24px'}}/>
                    <div style={{
                        backgroundColor: '#000000',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        maxHeight: '527px',
                        overflow: 'hidden'
                        // minHeight: '440px',
                        // marginTop: '24.5px'
                    }}>
                        <SignInForm input={input} handleInputChange={handleInputChange} />
                        <SidePhoto style={{flexBasis: '35vw'}}/>
                    </div>
                    <div className={classes.collapsiblePadding} style={{flexBasis: '4vh'}} />
                    <div className={classes.buttonGroup}>
                        <Button
                            component={Link}
                            to="/"
                            className={classes.button}
                            size="large"
                            color="inherit"
                        >Cancel</Button>
                        <Button className={classes.button} onClick={handleSubmit} style={{
                            marginLeft: '37px',
                            color: '#FFFFFF',
                            backgroundColor: '#000000'
                        }}>Sign In</Button>
                    </div>
                    <div className={classes.collapsiblePadding} style={{flexBasis: '4vh'}} />
                </div>
            </div>
        </>
    );
}