import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    IconButton
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Grid from '@material-ui/core/Grid';

import { useFirebase } from 'providers/firebase/FirebaseContext';
import { useCurrentUser } from 'providers/user/UserContext';

import MediaVideo from 'components/media/MediaVideo';
import VisibilitySensor from "react-visibility-sensor";


const useStyles = makeStyles((theme) => ({
    formContainer: {
        flexGrow: 1,
        flexBasis: '70vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'stretch',
        padding: 0,
        margin: 0,
        right: 0,
        overflow: 'scroll',
        left: 'auto',
        flexShrink: 0,
        width: '100%',
        maxWidth: 'none'
    },
    formTitle: {
        fontFamily: 'Montserrat',
        fontSize: '25pt',
        fontWeight: '800',
        justifyContent: 'left',
        alignSelf: 'flex-start',
        marginLeft: '120px'
    },
    dashboardGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    blackBar: {
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'row',
        height: '60vh',
        justifyContent: 'center',
        width: '100%',
        // flexBasis: '700px'
    },
    collapsiblePadding: {
        flexShrink: 10,
        flexBasis: '10vh'
    },
    gridContainer: {
        overflow: 'scroll',
        overflowX: 'hidden',
        maxWidth: '90%',
        scrollbarWidth: 'none'
        // flexGrow: 10,
        // flexBasis: '5%'
    }
}));

function Media() {
    const classes = useStyles();
    const firebase = useFirebase();
    const { data } = useCurrentUser();
    const [mediaSnapshots, setMediaSnapshots] = useState([]);

    const [maxFound, setMaxFound] = useState(false);


    useEffect(() => {
        if (maxFound === false) {
            firebase.getMediaContent()
                .then(result => {
                    setMaxFound(result.docs.length < 20);
                    setMediaSnapshots([...result.docs]);
                })
                .catch(e => console.log('get media error', e));
        }
    }, [firebase, data]);

    const getMore = () => {
        if (maxFound === false && mediaSnapshots[mediaSnapshots.length - 1]) {
            firebase.getMediaContent(mediaSnapshots[mediaSnapshots.length - 1])
                .then(result => {
                    setMaxFound(result.docs.length < 20);
                    setMediaSnapshots([...mediaSnapshots, ...result.docs]);
                })
                .catch(e => console.log('get next media error', e));
        }
    };

    return (
        <>
            <div className={classes.collapsiblePadding} />
            <div className={classes.formContainer}>

                <div className={classes.formTitle}>MEDIA</div>

                <div className={classes.blackBar}>
                    <div className={classes.collapsiblePadding} style={{ flexBasis: '5%', flexGrow: 1, flexShrink: 10 }} />
                    <Grid item className={classes.gridContainer}>

                        <div className="horizontalProgress"></div>
                        <div className={classes.dashboardGrid}>
                            {mediaSnapshots.map((snapshot, i) => (
                                <>
                                    {(i === mediaSnapshots.length - 1) ? (<VisibilitySensor onChange={(isVisible => { if (isVisible) { getMore(); } })}>
                                        <MediaVideo key={`media-${i}`} media={snapshot.data()} />
                                    </VisibilitySensor>
                                    ) : (
                                            <MediaVideo key={`media-${i}`} media={snapshot.data()} />
                                        )}
                                </>
                            ))}
                            {mediaSnapshots.length > 4 && [0, 1, 2].map((index) => {
                                return (<div style={{ width: '260px', height: '5px', margin: '20px', visibility: 'hidden' }} />)
                            })
                            }
                        </div>
                    </Grid>
                    <div className={classes.collapsiblePadding} style={{ flexBasis: '5%', flexGrow: 1, flexShrink: 10 }} />
                </div>
            </div>
            <div className={classes.collapsiblePadding} />
        </>
    )
}

export default Media;