import React from "react"

import AppStoreBadge from 'res/appstore_badge.svg';

class AppStoreButton extends React.Component {

    render() {
        const aStyle = {
            display: 'inline-block',
            overflow: 'hidden',
            background: 'url(' + AppStoreBadge + ')',
            backgroundSize: 'cover',
            width: this.props.width || 165,
            height:  this.props.height || 40,
        };

        return (
            <div>
                <a
                    style = {aStyle}
                    href  = {this.props.url}
                >
                </a>
            </div>
        )
    }
}

export default AppStoreButton;