import React, {
    useState,
    useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';

import PostForm from 'components/post/PostForm';
import PostPreview from 'components/post/PostPreview';
import TagSelector from 'components/tags/TagSelector';

import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import SidePhoto from 'components/photo-background/SidePhoto.js';

import SimpleDialog from 'components/modals/SimpleDialog.js';

import { Modal } from "@material-ui/core";
import JobTags from "data/employer_tags.json";

import { useFirebase } from 'providers/firebase/FirebaseContext';
import { useCurrentUser } from 'providers/user/UserContext';

import { useHistory } from "react-router-dom";

import { convertTagsToArrays, convertTagsToKeyed } from 'utils/TagUtils'

const useStyles = makeStyles(() => ({
    formContainer: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        margin: 0,
        // marginTop: '10vh',
        right: 0,
        // overflow: 'scroll',
        // paddingBottom: '100px',
        left: 'auto',
        flexShrink: 0,
        width: '100%',
        maxWidth: 'none',
        minWidth: '900px'
    },
    formTitle: {
        fontFamily: 'Montserrat',
        fontSize: '33px',
        fontWeight: '800',
        justifyContent: 'left'
    },
    formSubtitle: {
        fontFamily: 'Montserrat',
        fontSize: '7.5pt',
        paddingBottom: '6px',
        paddingLeft: '10px',
        fontWeight: '400'
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        // marginTop: '97px'
    },
    button: {
        width: '158px',
        height: '34px',
        border: 'solid 1px #000000',
        borderRadius: '0px',
        fontSize: '16px',
        lineHeight: '1.6'
    },
    closeContainer: {
        width: '100%',
        background: 'black',
        textAlign: 'right',
        padding: '0px 5px',
    },
    collapsiblePadding: {
        flexShrink: 10,
        flexGrow: 10,
        flexBasis: '8vh'
    }
}));

const stringsForPage = (page) => {
    switch (page) {
        case 'edit':
            return {
                title: 'POST OPPORTUNITY',
                subtitle: '',
                nextButton: 'PREVIEW'
            }
        case 'preview':
            return {
                title: 'OPPORTUNITY POST PREVIEW',
                subtitle: 'This is how your post will appear to the Candidate.',
                nextButton: 'POST OPPORTUNITY'
            };
        default: {
            return {};
        }
    }
};


export default function CreatePostContainer(props) {
    console.log('props: ', props)
    const firebase = useFirebase();
    const { data } = useCurrentUser();
    const history = useHistory();

    const isEditing = props.job.hasOwnProperty('position');

    const error = { severity: 'error' };

    const handleNextClick = () => {
        if (page === 'edit')
            handlePreviewClick();
        else if (page === 'preview')
            handleSubmitClick();
    };

    const validateURL = (urlString) => {
        try {
            new URL(urlString);
            return true;
        } catch (_) {
            return false;
        }
    };

    const handlePreviewClick = () => {
        // validation

        if (input.jobTitle.length === 0)
            return props.setAlert({ ...error, message: 'Please enter a title.' });

        if (input.jobDescription.length === 0)
            return props.setAlert({ ...error, message: 'Please provide a description.' });

        if (input.jobCompanyVideo.length === 0)
            return props.setAlert({ ...error, message: 'Please provide a company video.' });

        if (!validateURL(input.jobLink)) {
            // try prefixing
            let prependedURLString = 'http://' + input.jobLink;
            if (validateURL(prependedURLString)) {
                setInput({ ...input, jobLink: prependedURLString });
            } else {
                return props.setAlert({ ...error, message: 'Please provide a valid link to apply.' });
            }
        }


        if (!tagsSelected('location'))
            return props.setAlert({ ...error, message: 'Please select a location.' });

        setPage('preview');

    };

    const handleSubmitClick = () => {
        // validation is already complete
        let job = {
            ...props.job,
            position: input.jobTitle,
            jobDescription: input.jobDescription,
            companyVideoURL: input.jobCompanyVideo,
            hiringManagerVideoURL: input.jobManagerVideo,
            applicationLink: input.jobLink,
            tagData: input.tagData,
            location: jobLocation(),
        };

        if(!isEditing) {
            job = {
                ...job,
                promotionPriority: 100,
                companyName: input.companyName,
                creator: firebase.auth.currentUser.uid,
                organizationId: data.organization,
            };
        }


        if(isEditing) {
            firebase.updateJob(job).then(result => {
                console.log('Job updated successfully.');
                props.setAlert({ severity: 'success', message: 'Opportunity has been updated!' });
                history.goBack();
            }).catch(error => {
                props.setAlert({ ...error, message: 'Error updating opportunity.  Please try again.' });
            });
        } else {
            firebase.postJob(job).then(result => {
                console.log('Job posted successfully.');
                props.setAlert({severity: 'success', message: 'Opportunity has been posted!'});
                history.push("/dashboard");
            }).catch(error => {
                props.setAlert({...error, message: 'Error posting opportunity.  Please try again.'});
            });
        }
    };

    const jobLocation = () => {
        return input.tagData.categories.filter(category => category.id === 'location')
            .flatMap(category => category.subCategories.flatMap(subcategory => subcategory.tags.flatMap(tag => tag.displayString)))[0];
    };

    const tagsSelected = (categoryId) => {
        return convertTagsToKeyed(input.tagData)[categoryId] !== undefined;
    };

    const handleBackClick = () => {
        if (page === 'preview') {
            setPage('edit');
        } else {
            history.goBack();
        }
    };

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const handleDeleteClick = () => {
        setDeleteDialogOpen(true);
    };

    const onConfirmDelete = () => {
        setDeleteDialogOpen(false);
        firebase.deleteJob(props.job)
            .then(() => {
                props.setAlert({ severity: 'success', message: 'Post removed!' });
                history.goBack();
            })
            .catch(e => {
                console.log('error deleting post: ', e);
                props.setAlert({ severity: 'error', message: 'Error removing post.  Please try again.' });
            })

    };

    const handleTagClick = (e) => {
        console.log('Tag clicked: ' + e.currentTarget.name);
        setOpen(true);
    };


    const handleInputChange = (e) => {

        if (e.currentTarget.name === 'jobDescription' && e.currentTarget.value.length > 4000) {
            return;
        }

        setInput({
            ...input,
            [e.currentTarget.name]: e.currentTarget.value
        });
    };


    const [open, setOpen] = React.useState(false);

    const [page, setPage] = React.useState('edit');   // track edit and preview

    const handleClose = () => {
        setOpen(false);
    };

    const applyTags = (tagData) => {
        console.log(tagData);

        setInput({
            ...input,
            tagData: convertTagsToArrays(JobTags, tagData)
        });
        setOpen(false);
    };

    const createInitialTagData = () => {
        // no tags initially selected
        return {};
    };

    const [input, setInput] = useState({
        jobTitle: props.job.position || "",
        jobDescription: props.job.jobDescription || "",
        jobCompanyVideo: props.job.companyVideoURL || "",
        jobManagerVideo: props.job.hiringManagerVideoURL || "",
        jobLink: props.job.applicationLink || "",
        tagData: props.job.tagData || createInitialTagData(),
        companyName: props.job.companyName || data.companyName
    });

    useEffect(() => {
        setInput({
            jobTitle: props.job.position || "",
            jobDescription: props.job.jobDescription || "",
            jobCompanyVideo: props.job.companyVideoURL || "",
            jobManagerVideo: props.job.hiringManagerVideoURL || "",
            jobLink: props.job.applicationLink || "",
            tagData: props.job.tagData || createInitialTagData(),
            companyName: props.job.companyName || data.companyName
        })
    }, [props.job]);

    const classes = useStyles();

    return (
        <div>
            <div className={classes.formContainer}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    paddingLeft: '278px',
                    height: '67px',
                    width: '100%',
                    maxWidth: 'none'
                }}>
                    <div className={classes.formTitle}>{ isEditing ? "EDIT POST" : stringsForPage(page).title}</div>
                </div>
                <div className={classes.collapsiblePadding} style={{flexBasis: '24px'}}/>
                <div style={{
                    backgroundColor: page === 'edit' ? '#000000' : '#FFFFFF',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    maxHeight: '527px',
                    overflow: 'hidden'
                    // marginTop: '24.5px'
                }}>
                    {page === 'edit' && (
                        <>
                            <PostForm input={input} keyedTags={convertTagsToKeyed(input.tagData)} allTags={JobTags} handleInputChange={handleInputChange} handleTagClick={handleTagClick} />
                            <SidePhoto />
                        </>
                    )}
                    {page === 'preview' && (
                        <PostPreview input={input}
                                     videos={[{videoURL: input.jobManagerVideo, videoType: 'hiring_manager_video'},
                                         {videoURL: input.jobCompanyVideo, videoType: 'company_video'}
                                         ].filter(video => { return (video.videoURL && video.videoURL.length > 0) })}
                                     type="jobPost" />
                    )}
                </div>
                <div className={classes.collapsiblePadding} style={{flexBasis: '4vh'}} />
                <div className={classes.buttonGroup}>
                    <Button className={classes.button} onClick={handleBackClick} style={{
                        marginRight: '37px'
                    }}>Back</Button>
                    <Button className={classes.button} onClick={handleNextClick} style={{
                        color: '#FFFFFF',
                        backgroundColor: '#000000'
                    }}> {(isEditing && page === 'preview' ) ? "UPDATE POST" : stringsForPage(page).nextButton}</Button>
                    { (isEditing && page === 'edit') && (
                        <Button className={classes.button} onClick={handleDeleteClick} style={{
                            color: '#FFFFFF',
                            backgroundColor: '#000000',
                            marginLeft: '37px'
                        }}>REMOVE</Button>
                    )}
                </div>
                <div className={classes.collapsiblePadding} style={{flexBasis: '4vh'}} />
            </div>
            <Modal open={open}
                   onClose={handleClose}
                   style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                   disableAutoFocus
                   disableEnforceFocus
            >
                <div>
                    <div className={classes.closeContainer}>
                        <IconButton className={classes.closeButton} onClick={handleClose}>
                            <Close fontSize='24px' style={{ fill: '#FFFFFF' }} />
                        </IconButton>
                    </div>
                    <TagSelector fullTagList={JobTags} jobTagData={convertTagsToKeyed(input.tagData)} applyTags={(tagData) => applyTags(tagData)} />
                </div>
            </Modal>
            <SimpleDialog open={deleteDialogOpen} onConfirm={onConfirmDelete} onClose={ () => {setDeleteDialogOpen(false)}}/>
        </div>
    );
}