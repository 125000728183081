import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';

import { makeStyles, withStyles, fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative'
    },
    inputLabel: {
        color: theme.palette.primary.main,
        fontSize: '20px',
        lineHeight: '1.19'
    },
    characterCountContainer: {
        position: 'absolute',
        right: '38px',
        bottom: '9px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        color: '#585858',
        "&:hover": {
            backgroundColor: "transparent"
        }
    }
}));

const BootstrapTextArea = withStyles((theme) => ({
    root: {
        marginTop: '50px',
        'label + &': {
            marginTop: '5px',
        },
    },
    input: {
        borderRadius: 0,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        // width: '310px',
        height: '231px',
        minHeight: '231px',
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: 'Montserrat',
        fontWeight: '400',
        // fontSize: '13px',
        lineHeight: '1.31',
        color: '#000000',
        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);

export default function FormTextArea(props) {

    const classes = useStyles();

    return (
        <div style={props.style} className={classes.container}>
            <InputLabel className={classes.inputLabel}>
                {props.fieldName}
            </InputLabel>
            <BootstrapTextArea style={props.inputStyle} multiline={true} rows={10} name={props.name} value={props.value} placeholder={props.placeholderText} type={props.type} onChange={props.onChange} />
            <div className={classes.characterCountContainer}> {props.value.length + '/4000'} </div>
        </div>
    );
}