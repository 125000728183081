/* eslint-disable no-unused-vars */
import React from 'react';

import FormField from 'components/form-fields/FormField';
import FormTextArea from 'components/form-fields/FormTextArea';
import TagContainer from 'components/tags/TagContainer';

import VideoToolTip from 'components/create-post/VideoToolTip';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/core/SvgIcon/SvgIcon";


const useStyles = makeStyles((theme) => ({
    container: {
        paddingLeft: '0px',
        margin: 'auto'
        // marginLeft: '278px',
        // marginTop: '23px'
    },
    column: {
        top: '57px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: '0px',
        marginRight: '57px'
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    radioLabel: {
        fontSize: '16pt'
    },
    collapsiblePadding: {
        flexBasis: '278px',
        flexShrink: 10,
        minWidth: '10px'
    }
}));


export default function PostForm(props) {

    const classes = useStyles();

    return (
        <Container component="main" className={classes.container}>
            <div className={classes.columnContainer}>
                <div className={classes.collapsiblePadding} />
                <div className={classes.column}>
                        <FormField value={props.input.jobTitle} onChange={props.handleInputChange} name="jobTitle" placeholderText="Product Engineer, Nike Football" fieldName="Title" style={{marginTop:'20px'}} inputStyle={{fontSize: '11px', width: '398px'}}/>
                        <FormTextArea value={props.input.jobDescription}
                                      onChange={props.handleInputChange}
                                      name="jobDescription"
                                      placeholderText="Copy and Paste high level opportunity description from your
                                                       company website posting. We don’t need the full
                                                       description, but enough to drive candidates to your
                                                       Careers section to apply."
                                      fieldName="Description"
                                      style={{marginTop:'36px'}}
                                      inputStyle={{fontSize: '11px', width: '398px'}}/>
                </div>
                <div className={classes.column}>
                        <FormField
                            value={props.input.jobCompanyVideo}
                            onChange={props.handleInputChange}
                            name="jobCompanyVideo"
                            placeholderText="Paste URL from Youtube, Vimeo, etc here"
                            fieldName="Add video highlighting the company"
                            style={{marginTop:'20px'}}
                            inputStyle={{fontSize: '11px', width: '398px', color:'#000000'}}
                            endAdornment={( <VideoToolTip/> )}
                        />
                        <FormField
                            value={props.input.jobManagerVideo}
                            onChange={props.handleInputChange}
                            name="jobManagerVideo"
                            placeholderText="Paste URL from Youtube, Vimeo, etc here"
                            fieldName="Add opportunity video"
                            style={{marginTop:'20px'}}
                            inputStyle={{fontSize: '11px', width: '398px'}}
                            endAdornment={( <VideoToolTip/> )}
                            subtitle="What specifically are you looking for?"
                            optionalFlag
                        />
                        <FormField value={props.input.jobLink} onChange={props.handleInputChange} name="jobLink" placeholderText="Paste URL from your company posting" fieldName="Link to apply" style={{marginTop:'20px'}} inputStyle={{fontSize: '11px', width: '398px'}}/>
                        <TagContainer
                            fieldName="Add Tags"
                            allTags={props.allTags}
                            keyedTags={props.keyedTags}
                            onClick={props.handleTagClick}/>
                </div>
            </div>
        </Container>
    );


}