import React from 'react';

import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import { useCurrentUser } from 'providers/user/UserContext';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    bottomBar: {
        // position: 'fixed',
        bottom: '0px',
        margin: '0px',
        padding: '0px',
        maxWidth: 'none',
        minWidth: '940px',
        // position: 'absolute'
        // flexDirection: 'row',
    },
    blackBar: {
        textAlign: 'center',
        backgroundColor: 'black',
        height: '81px',
        margin: '0px',
        maxWidth: 'none',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    contactUsButton: {
        display: 'inline-block',
        textAlign: 'center',
        color: theme.palette.primary.main,
        outlineColor: theme.palette.primary.main,
        outlineWidth: '1px',
        outlineStyle: 'solid',
        borderColor: 'transparent',
        width: '335px',
        height: '41px',
        fontSize: '20px',
        lineHeight: '1.5'
        // position: 'fixed',
        // top: '40px',
        // left: '50vw',
    },
    termsAndCopyright:  {
        // position: 'absolute',
        margin: '0 50px 0 0',
        display: 'inline-block'
    },
    termsPrivacy: {
        textDecoration: 'underline',
        float: 'center',
        textAlign: 'center',
        height: 11,
        fontFamily: 'Montserrat',
        fontSize: 9,
        fontWeight: 'normal',
        lineHeight: 1.22,
        display: 'inline-block',
        color: '#ffffff',
        marginLeft: '60px'
    },
    copyright: {
        float: 'center',
        textAlign: 'center',
        height: 11,
        fontFamily: 'Montserrat',
        fontSize: 9,
        fontWeight: 'normal',
        lineHeight: 1.22,
        display: 'inline-block',
        color: '#ffffff',
        marginLeft: '60px'
    }
}));

export default function BottomBar() {
    const { data } = useCurrentUser();
    const classes = useStyles();

    return (
        <Container className={classes.bottomBar}>
            <div className={classes.blackBar}>
                <div className={classes.termsAndCopyright} style={{visibility: 'hidden'}}>
                    <Typography className={classes.termsPrivacy} component={Link} to="/legal" target='_blank'>{'Terms & Privacy'}</Typography>
                    <Typography className={classes.copyright}>{'2021 Athletes Launch Inc.'}</Typography>
                </div>
                {data && data.type === 'employer' && (
                    <Button href='mailto:customersupport@athleteslaunch.com' className={classes.contactUsButton} size="medium">
                        CONTACT US
                    </Button>
                )}
                <div className={classes.termsAndCopyright}>
                    <Typography className={classes.termsPrivacy} component={Link} to="/legal" target='_blank'>{'Terms & Privacy'}</Typography>
                    <Typography className={classes.termsPrivacy} component='a' href='mailto:customersupport@athleteslaunch.com'>{'Contact Us'}</Typography>
                    <Typography className={classes.copyright}>{'2021 Athletes Launch Inc.'}</Typography>
                </div>


            </div>
        </Container>
    );
}