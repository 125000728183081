import React, {
    useState
} from 'react';

import { makeStyles } from '@material-ui/core/styles';

import ProfileForm from 'components/profile/Profile.js';
import AthleteProfileForm from 'components/profile/AthleteProfile';
import TagSelector from 'components/tags/TagSelector';
import PostPreview from 'components/post/PostPreview';

import Button from "@material-ui/core/Button";

import SidePhoto from 'components/photo-background/SidePhoto';

import AthleteTags from "data/athlete_tags.json";

import { convertTagsToArrays, convertTagsToKeyed } from 'utils/TagUtils'


import { useFirebase } from 'providers/firebase/FirebaseContext';
import { useCurrentUser } from 'providers/user/UserContext';
import { useHistory } from 'react-router-dom';
import {Modal} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    formContainer: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        margin: 0,
        right: 0,
        left: 'auto',
        flexShrink: 0,
        width: '100%',
        maxWidth: 'none',
        minWidth: '900px'
    },
    formTitle: {
        fontFamily: 'Montserrat',
        fontSize: '33px',
        fontWeight: '800',
        justifyContent: 'left'
    },
    subtitle: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontWeight: '400'
    },
    titleStack: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left'
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    button: {
        width: '158px',
        height: '34px',
        border: 'solid 1px #000000',
        borderRadius: '0px',
        fontSize: '16px',
        lineHeight: '1.6'
    },
    collapsiblePadding: {
        flexShrink: 10,
        flexGrow: 10,
        flexBasis: '8vh'
    },
    closeContainer: {
        width: '100%',
        background: 'black',
        textAlign: 'right',
        padding: '0px 5px',
    }
}));

const strings = {
    athlete: {
        baseProfile: {
            title: 'CREATE YOUR PROFILE',
            subtitle: '',
            backButtonText: 'Cancel',
            nextButtonText: 'Next'
        },
        athleteProfile: {
            title: 'CREATE YOUR PROFILE',
            subtitle: '',
            backButtonText: 'Back',
            nextButtonText: 'Preview'
        },
        profilePreview: {
            title: 'PROFILE PREVIEW',
            subtitle: 'This is how your profile will appear to the employer',
            backButtonText: 'Back',
            nextButtonText: 'Submit'
        }
    },
    employer: {
        baseProfile: {
            title: 'CREATE YOUR PROFILE',
            subtitle: '',
            backButtonText: 'Cancel',
            nextButtonText: 'Submit'
        }
    }
};


export default function ProfileContainer(props) {
    const firebase = useFirebase();
    const { data } = useCurrentUser();
    const history = useHistory();

    const isEditing = data.hasOwnProperty('name');

    const validateBaseProfile = () => {
        const error = {severity: 'error'};

        let isValid = true;

        if (input.firstName.length === 0) {
            props.setAlert({...error, message: 'Please enter your first name.'});
            isValid = false;
        } else if (input.lastName.length === 0) {
            props.setAlert({...error, message: 'Please enter your last name.'});
            isValid = false;
        } else if (data.type ==='employer' && input.phoneNumber.length < 3) {            // not doing full phone number validation
            props.setAlert({...error, message: 'Please provide a valid phone number.'});
            isValid = false;
        }

        return isValid;
    };


    const saveBaseProfile = () => {
        let userProfile = {
            email: input.email,
            name: {
                first: input.firstName,
                last: input.lastName
            },
            phoneNumber: input.phoneNumber
        };

        if (data.type === 'employer') {
            userProfile.companyName = input.companyName
        }

        firebase.updateProfile(userProfile).then(result => {
            console.log('Updated profile.');
            props.setAlert({severity: 'success', message: 'Profile saved!'});

            if (data.type === 'employer' && data.verified !== true) {
                history.push('/gameplan');
                props.setModalOpen(true);
            } else {
                history.push('/dashboard');
            }

        }).catch(error => {
            props.setAlert({severity: 'error', message: 'Error saving profile.  Please try again.'});
        });
    }

    const saveAthleteProfile = () => {

        return new Promise((resolve) => {
            // file upload first
            if (files.length) {
                return firebase.uploadFiles(files).then(metadata => {
                    return resolve(metadata);
                });
            } else {
                return resolve();
            }
        }).then((fileMetadata) => {

            let profile = {
                email: input.email,
                name: {
                    first: input.firstName,
                    last: input.lastName
                },
                phoneNumber: input.phoneNumber,
                tagData: input.tagData,
                careerHighlightVideoURL: input.careerHighlightVideoURL,
                proudestTeamMomentVideoURL: input.proudestTeamMomentVideoURL,
                resumeFilePaths: fileMetadata !== undefined ? fileMetadata.map(snapshot => snapshot.metadata.fullPath) : []
            };

            return firebase.updateProfile(profile);

        });
    };

    const handleSubmit = () => {
        switch (page) {
            case 'baseProfile':
                if(validateBaseProfile()) {
                    if(data.type === 'employer') saveBaseProfile();
                    else setPage('athleteProfile');
                }
                break;
            case 'athleteProfile':
                setPage('profilePreview');
                break;
            case 'profilePreview':
                saveAthleteProfile().then(() => {
                    console.log('saved profile');
                    props.setAlert({severity: 'success', message: 'Profile saved!'});
                    history.push('/dashboard');
                }).catch(e => {
                    console.log('error saving profile: ', e);
                });
                break;
            default: break;
        }
    };

    const handleBackClicked = () => {
        switch (page) {
            case 'athleteProfile':
                setPage('baseProfile');
                break;
            case 'profilePreview':
                setPage('athleteProfile');
                break;
            default:
                history.push('/dashboard');
        }
    };


    const handleInputChange = (e) => {
        setInput({
            ...input,
            [e.currentTarget.name]: e.currentTarget.value
        });
    };

    const handlePhoneChange = (e) => {
        setInput({
            ...input,
            phoneNumber: e
        });
    };


    let firstName = "", lastName = "";
    if (data.name && data.name.first) {
        firstName = data.name.first;
    }
    if (data.name && data.name.last) {
        lastName = data.name.last;
    }

    let initialTags = (data.tagData !== undefined && data.tagData.categories !== undefined && data.tagData.categories.length > 0) ?
        data.tagData : { categories: []};

    const [input, setInput] = useState({
        email: data.email || "",
        firstName: firstName,
        lastName: lastName,
        phoneNumber: data.phoneNumber || "",
        companyName: data.companyName || "",
        tagData: initialTags,
        careerHighlightVideoURL: data.careerHighlightVideoURL || "",
        proudestTeamMomentVideoURL: data.proudestTeamMomentVideoURL || "",
    });


    React.useEffect(() => {
        firebase.downloadFiles(data.resumeFilePaths).then((r) => {
            console.log(r);

            setFiles(r);
        });
    }, [data.resumeFilePaths]);

    const [files, setFiles] = useState([]);
    const [page, setPage] = useState('baseProfile');
    const [open, setOpen] = useState(false);


    const handleClose = () => {
        setOpen(false);
    };

    const handleViewJobs = () => {
        history.push('/dashboard?viewJobs=true');
    };

    const applyTags = (tagData) => {
        console.log(tagData);

        setInput({
            ...input,
            tagData: convertTagsToArrays(AthleteTags, tagData)
        });
        setOpen(false);
    };

    const handleTagClick = (e) => {
        console.log('Tag clicked: ' + e.currentTarget.name);
        setOpen(true);
    };

    const classes = useStyles();

    return (
        <>
            <div>
                <div className={classes.formContainer}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        paddingLeft: '278px',
                        height: '67px',
                        width: '100%',
                        maxWidth: 'none'
                    }}>
                        <div className={classes.titleStack}>
                            <div className={classes.formTitle}>{isEditing? "UPDATE PROFILE" : strings[data.type][page].title}</div>
                            <div className={classes.subtitle}>{strings[data.type][page].subtitle}</div>
                        </div>
                    </div>
                    <div className={classes.collapsiblePadding} style={{flexBasis: '24px'}}/>
                    <div style={{
                        backgroundColor: page === 'profilePreview' ? '#FFFFFF' : '#000000',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        maxHeight: '527px',
                        overflow: 'hidden'
                        // marginTop: '24.5px'
                    }}>
                        {page === 'baseProfile' && (
                            <>
                                <div className={classes.collapsiblePadding} style={{flexBasis:'440px'}}/>
                                <ProfileForm input={input} handleInputChange={handleInputChange} handlePhoneChange={handlePhoneChange} type={data.type} verified={data.verified} handleViewJobs={handleViewJobs}/>
                                <div className={classes.collapsiblePadding} style={{flexBasis:'40px'}}/>
                                <SidePhoto style={{flexBasis: '35vw'}}/>
                            </>
                        )}
                        {page === 'athleteProfile' && (
                            <>
                                <AthleteProfileForm input={input}
                                                    handleInputChange={handleInputChange}
                                                    allTags={AthleteTags}
                                                    keyedTags={convertTagsToKeyed(input.tagData)}
                                                    handleTagClick={handleTagClick}
                                                    files={files}
                                                    setFiles={setFiles}
                                />
                                <SidePhoto />
                            </>
                        )}
                        {page === 'profilePreview' && (
                            <PostPreview
                                input={input}
                                videos={[{videoURL: input.careerHighlightVideoURL, videoType: 'career_highlight'},
                                    {videoURL: input.proudestTeamMomentVideoURL, videoType: 'proudest_team_moment'}
                                ].filter(video => { return (video.videoURL && video.videoURL.length > 0) })}
                                type='athleteProfile'
                                resumeFilePaths={data.resumeFilePaths}
                            />

                        )}
                    </div>
                    <div className={classes.collapsiblePadding} style={{flexBasis: '4vh'}}/>

                    <div className={classes.buttonGroup}>
                        {(firstName.length > 0 || page !== 'baseProfile') && (
                            <Button className={classes.button}
                                    style={{marginRight: '37px'}}
                                    onClick={handleBackClicked}
                            >{strings[data.type][page].backButtonText} </Button>
                        )}
                        <Button className={classes.button} onClick={handleSubmit} style={{
                            color: '#FFFFFF',
                            backgroundColor: '#000000'
                        }}>{strings[data.type][page].nextButtonText} </Button>
                    </div>
                    <div className={classes.collapsiblePadding} style={{flexBasis: '4vh'}}/>

                </div>
                <Modal open={open}
                       onClose={handleClose}
                       style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                       disableAutoFocus
                       disableEnforceFocus
                >
                    <div>
                        <div className={classes.closeContainer}>
                            <IconButton className={classes.closeButton} onClick={handleClose}>
                                <Close fontSize='24px' style={{ fill: '#FFFFFF' }} />
                            </IconButton>
                        </div>
                        <TagSelector fullTagList={AthleteTags} jobTagData={convertTagsToKeyed(input.tagData)} applyTags={(tagData) => applyTags(tagData)} />
                    </div>
                </Modal>
            </div>
        </>
    );
}