import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import {useFirebase} from "./FirebaseContext";

export default function FirebaseAnalytics() {
    let location = useLocation();
    let firebase = useFirebase();

    useEffect(() => {
        const page_path = location.pathname + location.search;
        firebase.analytics.setCurrentScreen(page_path);
        firebase.analytics.logEvent("page_view", { page_path });
    }, [location]);
    return null;
}