import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';

import { makeStyles, withStyles, fade } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        color: theme.palette.primary.main,
        fontSize: '20px',
        lineHeight: '1.19'
    },
    subtitleLabel: {
        color: theme.palette.primary.main,
        fontSize: '14px',
        lineHeight: '1.19',
        fontFamily: 'Montserrat',
        marginBottom: '4px'
    },
    optionalFlag: {
        color: theme.palette.primary.main,
        fontSize: '10px',
        lineHeight: '1.19',
        fontFamily: 'Montserrat',
    }
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        // marginTop: '50px',
        height: '33px',
        'label + &': {
            marginTop: '5px',
        }
    },
    input: {
        borderRadius: 0,
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        // width: '310px',
        padding: '7px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: 'Montserrat',
        fontWeight: '400',
        fontSize: '11px',
        color: '#000000',
        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
        '&:disabled': {
            backgroundColor: '#c6c5c5'
        }
    },
}))(InputBase);

export default function FormField(props) {


    const classes = useStyles();

    return (
        <div style={props.style}>
            <InputLabel className={classes.inputLabel}>
                {props.fieldName}{props.optionalFlag && ( <span className={classes.optionalFlag}> (Optional)</span> )}
            </InputLabel>
            {props.subtitle !== undefined && (
                <InputLabel className={classes.subtitleLabel}>
                    {props.subtitle}
                </InputLabel>
            )}
            <div style={{display:'inline-block', position: 'relative'}}>
                <BootstrapInput name={props.name}
                                style={props.inputStyle}
                                value={props.value}
                                placeholder={props.placeholderText}
                                type={props.type}
                                onChange={props.onChange}
                                disabled={props.disabled}
                />
                {props.endAdornment}
            </div>
        </div>

    );

}
