import React from 'react';

import { Link, useHistory } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import logo from 'res/AthletesLaunch_Logo.png';

import { useCurrentUser } from 'providers/user/UserContext';
import { useFirebase } from 'providers/firebase/FirebaseContext';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 103,
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        minHeight: 103,
        justifyContent: 'center',
        position: 'relative',
        padding: '16px 64px',
    },
    logoImg: {
        width: 190,
    },
    button: {
        margin: theme.spacing(0, 1),
    },
    authSpace: {
        width: theme.spacing(8),
        height: 0,
        display: 'inline-block',
    }
}));

export default function NavBar(props) {
    const { loading, data } = useCurrentUser();
    const classes = useStyles();
    const firebase = useFirebase();
    const history = useHistory();

    const handleLogout = () => {
        firebase.logout();
        history.push('/login');
    };


    const handleOpenClick = (e) => {
        props.setModalOpen(true);
    };

    const renderNavAuth = () => data ? (
        <>
            <Button
                component={Link}
                to="/profile"
                className={classes.button}
                size="large"
                color="inherit"
            >
                <Typography variant="h6">PROFILE</Typography>
            </Button>
            <Button
                onClick={handleLogout}
                className={classes.button}
                size="large"
                color="inherit"
            >
                <Typography variant="h6">LOGOUT</Typography>
            </Button>
        </>
    ) : (
            <>
                <Button
                    component={Link}
                    to="/login"
                    variant="outlined"
                    size="large"
                    color="inherit"
                >
                    <Typography variant="h6">LOGIN</Typography>
                </Button>
                <Button
                    component={Link}
                    to="/signUp"
                    className={classes.button}
                    size="large"
                    color="inherit"
                >
                    <Typography variant="h6">SIGN UP</Typography>
                </Button>
            </>
        );

    const renderNavEmployer = () => (
        <>
            {data && data.type === 'employer' && data.verified === true && (
                <Button
                    component={Link}
                    to="/postJob"
                    className={classes.button}
                    size="large"
                    color="inherit"
                    onClick={() => props.setJob({})}
                >
                    <Typography variant="h6">POST OPPORTUNITY</Typography>
                </Button>
            )}
            {data && data.type === 'employer' && (data.verified === undefined || data.verified === false) && (
                <Button
                    className={classes.button}
                    size="large"
                    color="inherit"
                    onClick={handleOpenClick}
                >
                    <Typography variant="h6">POST OPPORTUNITY</Typography>
                </Button>
            )}
        </>
    );

    const renderNavButtons = () => (
        <>
            <Button
                component={Link}
                to="/gameplan"
                className={classes.button}
                size="large"
                color="inherit"
            >
                <Typography variant="h6">GAMEPLAN</Typography>
            </Button>

            {data && (data.type === 'athlete' || (data.type === 'employer' && data.verified === true)) && (
                <Button
                    component={Link}
                    to="/dashboard"
                    className={classes.button}
                    size="large"
                    color="inherit"
                >
                    <Typography variant="h6">DASHBOARD</Typography>
                </Button>
            )}
            {data && data.type === 'employer' && (data.verified === undefined || data.verified === false) && (
                <Button
                    className={classes.button}
                    size="large"
                    color="inherit"
                    onClick={handleOpenClick}
                >
                    <Typography variant="h6">DASHBOARD</Typography>
                </Button>
            )}

            {renderNavEmployer()}

            <Button
                component={Link}
                to="/media"
                className={classes.button}
                size="large"
                color="inherit"
            >
                <Typography variant="h6">MEDIA</Typography>
            </Button>
            <Button
                component={Link}
                to="/events"
                className={classes.button}
                size="large"
                color="inherit"
            >
                <Typography variant="h6">EVENTS</Typography>
            </Button>

            {data === null && (
                <>
                    <Button
                        component={Link}
                        to="/dashboard"
                        className={classes.button}
                        size="large"
                        color="inherit"
                    >
                        <Typography variant="h6">DASHBOARD</Typography>
                    </Button>
                    <div className={classes.authSpace}></div>
                </>
            )}

            {renderNavAuth()}
        </>
    );

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <div className={classes.logo}>
                        <Button component={Link} to="/" disableRipple disableElevation>
                            <img className={classes.logoImg} src={logo} alt="" />
                        </Button>
                    </div>
                    <div className={classes.grow}></div>
                    <div className={classes.navButtons}>
                        {!loading && renderNavButtons()}
                    </div>
                </Toolbar>
            </AppBar>

        </div>
    );
}