import React, { useEffect } from 'react';
import {
    makeStyles
} from '@material-ui/core';

import Typography from '@material-ui/core/Typography';

import gamePlan1 from 'res/game-plan-1.png';
import gamePlan2 from 'res/game-plan-2.png';
import gamePlan3 from 'res/game-plan-3.png';

import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    mainContainer: {
        paddingBottom: '24px',
        flexGrow: 1,
        // minHeight: '900px'
    },
    gamePlanContainer: {
        marginTop: '65px',
        flexGrow: 1,
        display: 'flex',
        backgroundColor: 'black',
        height: '572px',
        alignItems: 'center',
    },
    imagePane: {
        height: 476,
        width: 476,
        display: 'flex',
        flexDirection: 'row',
        backgroundSize: 'auto 100%',
        objectFit: 'cover',
    },
    collapsiblePadding: {
        flexShrink: 10,
        flexBasis: '10vh'
    },
    title: {
        paddingTop: '25px',
        fontFamily: "Montserrat",
        fontSize: "33px",
        fontWeight: 800,
        lineHeight: 1.2,
        color: '#000000',
        textAlign: 'center',
        width: '30%'
    },
    subTitle: {
        paddingTop: '25px',
        fontFamily: "Montserrat",
        fontSize: "16px",
        lineHeight: 1.2,
        color: '#000000',
        textAlign: 'left',
        width: '30%'
        // marginLeft: '-14px',
        // marginRight: '-14px'
    },
    justifiedFlex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'stretch'
    },
    justifyingPadding: {
        flexBasis: '20px',
        flexGrow: 10
    }
}));

function GamePlan() {
    const classes = useStyles();


    useEffect(() => { });

    return (
        <div className={classes.mainContainer}>
            <div className={classes.gamePlanContainer}>
                <div className={classes.collapsiblePadding} style={{ flexBasis: '10px', flexGrow: 10, flexShrink: 10 }} />
                <div className={classes.imagePane} style={{ backgroundImage: "url(" + gamePlan1 + ")", padding: 0, backgroundColor: '#edd814' }} />
                <div className={classes.collapsiblePadding} style={{ flexBasis: '10px', flexGrow: 10, flexShrink: 10, minWidth: '5px' }} />
                <div className={classes.imagePane} style={{ backgroundImage: "url(" + gamePlan2 + ")", padding: 0 }} />
                <div className={classes.collapsiblePadding} style={{ flexBasis: '10px', flexGrow: 10, flexShrink: 10, minWidth: '5px' }} />
                <div className={classes.imagePane} style={{ backgroundImage: "url(" + gamePlan3 + ")", padding: 0, backgroundColor: '#edd814' }} />
                <div className={classes.collapsiblePadding} style={{ flexBasis: '10px', flexGrow: 10, flexShrink: 10 }} />
            </div >
            <div>
                <div className={classes.justifiedFlex}>
                    <div className={classes.justifyingPadding} />

                    <Typography className={classes.title} variant="h6">
                        Identify Attributes
                    </Typography>
                    <div className={classes.justifyingPadding} />
                    <div className={classes.justifyingPadding} />
                    <Typography className={classes.title} variant="h6">
                        Take Action
                    </Typography>
                    <div className={classes.justifyingPadding} />
                    <div className={classes.justifyingPadding} />
                    <Typography className={classes.title} variant="h6">
                        Connection
                    </Typography>
                    <div className={classes.justifyingPadding} />
                </div>
            </div>
            <div>
                <div className={classes.justifiedFlex} style={{ paddingBottom: '0px' }}>
                    <div className={classes.justifyingPadding} />
                    <Typography className={classes.subTitle} variant="h6">
                        With a company user account, employers and sponsors can post
                        opportunities for current or former athletes, search athlete
                        profiles, and contact athletes through the system.
                    </Typography>
                    <div className={classes.justifyingPadding} />
                    <div className={classes.justifyingPadding} />
                    <Typography className={classes.subTitle} variant="h6">
                        Athletes can post competition and personal message videos on
                        their profile where they can also select the types of jobs,
                        internships, or sponsorships of interest.
                    </Typography>
                    <div className={classes.justifyingPadding} />
                    <div className={classes.justifyingPadding} />
                    <Typography className={classes.subTitle} variant="h6">
                        The Athletes Launch platform is designed to help make
                        introductions faster and easier. If your team, organization,
                        or company would like help maximizing results please <a href='mailto:customersupport@athleteslaunch.com'>contact&nbsp;us</a>.
                    </Typography>
                    <div className={classes.justifyingPadding} />

                </div>
            </div>
        </div>
    )

}

export default GamePlan;