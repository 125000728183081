import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';

import PhoneNumberField from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        color: theme.palette.primary.main,
        fontSize: '20px',
        lineHeight: '1.19'
    }
}));

export default function FormPhoneField(props) {
    const classes = useStyles();

    return (
        <div style={props.style}>
            <InputLabel className={classes.inputLabel}>
                {props.fieldName}
            </InputLabel>
            <PhoneNumberField country={props.value && props.value.length ? '' : 'us' }
                              preferredCountries={['us']}
                              countryCodeEditable={false}
                              regions={['north-america', 'europe']}
                              name={props.name} style={props.inputStyle}
                              value={props.value}
                              placeholder={props.placeholderText}
                              type={props.type}
                              onChange={props.onChange}
                              inputStyle={{ borderRadius: 0, width: '394px', fontSize:'11px' }}
            />
        </div>
    );
}