import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import * as Amplitude from 'amplitude-js';

import { v4 as uuidv4 } from 'uuid';

const config = {
  apiKey: "AIzaSyD0u-Iu0F1x3_ktwll2GOevp1_ssv84QGE",
  authDomain: "athletes-launch.firebaseapp.com",
  databaseURL: "https://athletes-launch.firebaseio.com",
  projectId: "athletes-launch",
  storageBucket: "athletes-launch.appspot.com",
  messagingSenderId: "527335828485",
  appId: "1:527335828485:web:533186c71be4d66599406c",
  measurementId: "G-4J827MQXEL"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.firestore = app.firestore();
    this.storage = app.storage();
    this.analytics = app.analytics();
    this.amplitudeClient = Amplitude.getInstance();
    this.amplitudeClient.init('90b4b8e3ab198e6d349ef4e59318cc6e');
  }

  findCompany(companyName) {
    return this.firestore
      .collection('organizations')
      .where('name', '==', companyName)
      .get();
  }

  login(username, password) {
    return this.auth.signInWithEmailAndPassword(username, password)
      .then(r => {
        console.log("login", r);
        return this.firestore.collection("users").doc(this.auth.currentUser.uid).get()
      })
      .then(r2 => {
        console.log("login users", r2);
      });
  }

  logout() {
    this.trackAnalyticsEvent('logout');
    this.analytics.setUserId('')
    this.auth.signOut();
  }

  useProfile(callback) {
    return this.firestore.collection('users').doc(this.auth.currentUser.uid).onSnapshot(callback)
  }

  errorMessageForAuthCode(code) {
    switch (code) {
      case 'auth/user-not-found': {
        return 'User with that email does not exist.';
      }
      case 'auth/email-already-in-use': {
        return 'A user with that email already exists.';
      }
      default: {
        return 'Error. Please try again.';
      }
    }
  }

  createEmployer(input) {
    // check if this org already exists
    return this.findCompany(input.companyName)
      .then(snapshot => {
        if (snapshot.empty) {
          // new org with user as admin, create it
          return this.firestore.collection("organizations").add({
            name: input.companyName,
            owner: this.auth.currentUser.uid,
            created: Date()
          }).then(result => {
            let profile = {
              type: 'employer',
              id: this.auth.currentUser.uid,
              email: input.email,
              created: Date(),
              isOwner: true,
              organization: result.id,
              companyName: input.companyName,
              resumeFilePaths: [],
              tagData: { categories: [] }
            };
            return this.firestore.collection("users").doc(this.auth.currentUser.uid).set(profile)
              .then(result => {
                return profile;
              });
          });
        } else {
          // should never have more than one org
          let org = snapshot.docs[0];
          let profile = {
            type: 'employer',
            id: this.auth.currentUser.uid,
            email: input.email,
            created: Date(),
            isOwner: false,
            organization: org.id,
            companyName: input.companyName,
            resumeFilePaths: [],
            tagData: { categories: [] }
          }
          return this.firestore.collection("users").doc(this.auth.currentUser.uid).set(profile)
            .then(result => {
              return profile;
            });
        }
      })
  }

  createAthlete(input) {
    let profile = {
      type: 'athlete',
      id: this.auth.currentUser.uid,
      email: input.email,
      created: Date(),
      resumeFilePaths: [],
      tagData: { categories: [] }
    }

    return this.firestore.collection("users").doc(this.auth.currentUser.uid).set(profile).then(result => {
      return profile;
    });
  }

  signUp(input) {
    return this.auth.createUserWithEmailAndPassword(input.email, input.password)
      .then(result => {

        this.trackAnalyticsEvent('sign_up', { userType: input.type });
        this.amplitudeClient.setUserId(this.auth.currentUser.uid);

        if (input.type === 'employer') {

          return this.createEmployer(input);
        } else {
          return this.createAthlete(input);
        }

      })
      // success is handled by firebase auth, just handle error
      .catch(error => {
        console.log(error);
        // for now, just assuming this failed at the auth step, rethrow error for the App to handle

        throw error;
      });
  }

  getProfile() {
    return this.firestore.collection("users").doc(this.auth.currentUser.uid).get();
  }

  updateProfile(profileUpdates) {
    return this.firestore.collection("users").doc(this.auth.currentUser.uid).update(profileUpdates)
  }

  downloadFiles(files, prefixString) {
    console.log(files);
    return Promise.all(files.map(file => {
      return this.storage.ref(file).getDownloadURL().then((url) => {
        return new Promise(resolve => {
          console.log(url);
          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function (event) {
            resolve(xhr.response);
          };
          xhr.open('GET', url);
          xhr.send();
        });

      }).catch(function (error) {
        console.log(error);
      });
    })
    ).then((r) => {
      return r.map((blob, index) => {
        let file = new File([blob], (prefixString !== undefined) ? [prefixString, index + 1].join('-') : uuidv4(), { type: blob.type });
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        });
        return file;
      });
    });
  }


  getOrganization(orgId) {
    return this.firestore.collection("organizations").doc(orgId).get();
  }

  updateJob(job) {

    const document = this.firestore.collection("jobs").doc(job.id);

    return document.set(job, { merge: true }).then(result => {
      console.log(result);
    })
  }

  postJob(job) {

    const document = this.firestore.collection("jobs").doc();

    return document.set({
      ...job,
      created: Date(),
      id: document.id
    }).then(result => {
      console.log(result);
    })
  }

  deleteJob(job) {
    const document = this.firestore.collection("jobs").doc(job.id);
    return document.delete()
  }

  uploadFiles(files) {

    let ref = this.storage.ref().child('resumes').child(this.auth.currentUser.uid);

    return Promise.all(
      files.map((file) => {
        let extension = file.name.split('.').pop();

        return ref.child([uuidv4(), extension].join('.')).put(file);
      })
    ).then((result) => {
      console.log('upload complete', result);
      return result;
    });
  }

  getAthletes(startingAfter) {
    if (!startingAfter) {
      return this.firestore.collection('users').where('type', '==', 'athlete')
        .limit(100)
        .get();
    } else {
      return this.firestore.collection('users').where('type', '==', 'athlete')
        .limit(100)
        .startAfter(startingAfter)
        .get();
    }
  }

  getJobs(startingAfter, organizationId) {
    if (!startingAfter) {
      if (organizationId && organizationId.length > 0) {
        console.log('org ID - ', organizationId);
        return this.firestore.collection('jobs')
          .where("organizationId", "==", organizationId)
          .orderBy('promotionPriority', 'desc')
          .limit(100)
          .get();
      } else {
        return this.firestore.collection('jobs')
          .orderBy('promotionPriority', 'desc')
          .limit(100)
          .get();
      }
    } else {
      if (organizationId && organizationId.length > 0) {
        console.log('org Id - ', organizationId);
        return this.firestore.collection('jobs')
          .where("organizationId", "==", organizationId)
          .orderBy('promotionPriority', 'desc')
          .limit(100)
          .startAfter(startingAfter)
          .get();
      } else {
        return this.firestore.collection('jobs')
          .orderBy('promotionPriority', 'desc')
          .limit(100)
          .startAfter(startingAfter)
          .get();
      }
    }
  }


  getDashboardContent(type, startingAfter, organizationId) {
    console.log('Getting content starting after: ', startingAfter);
    if (type === 'employer') {
      return this.getAthletes(startingAfter);
    } else if (type === 'athlete') {
      return this.getJobs(startingAfter);
    } else if (type === 'employerJobPosts') {
      return this.getJobs(startingAfter, organizationId);
    } else {
      return Promise.resolve([]);
    }
  }

  getEventsContent(startingAfter) {
    if (!startingAfter) {
      return this.firestore.collection('events')
          .where('isActive', '==', true)
          .orderBy('priority', 'desc')
          .limit(20)
          .get();
    } else {
      return this.firestore.collection('events')
          .where('isActive', '==', true)
          .orderBy('priority', 'desc')
          .limit(20)
          .startAfter(startingAfter)
          .get();
    }
  }

  getMediaContent(startingAfter) {
    if (!startingAfter) {
      return this.firestore.collection('media')
        .orderBy('created', 'desc')
        .limit(20)
        .get();
    } else {
      return this.firestore.collection('media')
        .orderBy('created', 'desc')
        .limit(20)
        .startAfter(startingAfter)
        .get();
    }
  }

  trackAnalyticsEvent(event, params) {
    this.analytics.logEvent(event, params);
    this.amplitudeClient.logEvent(event, params);
    console.log('tracking event -- ', event);
  }

  setUserId(userId) {
    console.log('setting user id: ', userId);
    this.amplitudeClient.setUserId(userId);
  }
}

export default Firebase;
