import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import { useCurrentUser } from 'providers/user/UserContext';


export default function PrivateRoute({ children, ...rest }) {

    const { data } = useCurrentUser();

    const userHasFirstName = () => {
        return (data && data.name !== undefined && data.name.first !== undefined && data.name.first.length > 0);
    };

    const render = ({location}) => {
        if (data && (userHasFirstName() || location.pathname === '/profile')) {
            return (
                children
            )
        } else if (data) {
            return (
                <Redirect
                    to={{
                        pathname: "/profile",
                        state: {from: location}
                    }}
                />
            )
        } else {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: {from: location}
                    }}
                />
            )
        }
    };

    return (
        <Route
            {...rest}
            render={render}
        />
    );
};