import React from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";

import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import questionIcon from 'res/question-mark.svg';



const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent'
    },
    iconContainer: {
        position: 'absolute',
        right: '-24px',
        top: '-5px',
        "&:hover": {
            backgroundColor: "transparent"
        }
    }
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        height: '36px',
        width: '94px',
        backgroundColor: '#eaeaea',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        fontSize: '7px',
        color: '#585858',
        borderRadius: '0',
        padding: '4px 1px',
        margin: '0 0 2px 0'
    },
}))(Tooltip);

export default function VideoToolTip(props) {


    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };


    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <div className={classes.iconContainer}>
                <HtmlTooltip
                    title={<div >
                        Post direct URLs for video.
                        Embedded links are not
                        supported at this time.</div>}
                    PopperProps={{
                        disablePortal: false
                    }}
                    onClose={handleTooltipClose}
                    open={open}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top-start"
                >
                    <IconButton onClick={handleTooltipOpen} style={{padding: '0'}}>
                        <img className={classes.icon} src={questionIcon} alt='Tooltip'/>
                    </IconButton>
                </HtmlTooltip>
            </div>
        </ClickAwayListener>

    );

}

