import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

// eslint-disable-next-line no-unused-vars
const bebas = {
    fontFamily: 'Bebas',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `
    local('Bebas'), 
    local('Bebas'),
    url('http://localhost:3000/src/res/bebas/Bebas-Regular.ttf') format('truetype'),
    url('http://localhost:3000/src/res/bebas/Bebas-Regular.woff') format('woff')
    `,
};

export default createMuiTheme( {
    palette: {
        primary: {
            main: '#edd714'
        },
        secondary: {
            main: '#edd714'
        }
    },
    typography: {
        fontFamily: 'Bebas Neue'
    },
    overrides: {
        MuiChip: {
            label: {
                paddingLeft: '16px',
                paddingRight: '16px',
            }
        },
        MuiCssBaseline: {
            '@global': {
                '*': {
                    'scrollbar-width': 'thin',
                },
                '*::-webkit-scrollbar': {
                    width: '4px',
                    height: '4px',
                },
                'body': {
                    minWidth: '940px',
                    width: 'auto !important',  /* Firefox will set width as auto */
                    // width: '980px;            /* As IE6 ignores !important it will set width as 980px; */
                    height: '100vh'
                }
            }
        }
    }
});




