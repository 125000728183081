


export const convertTagsToArrays = (allTags, selectedTags) => {

    console.log(`pre-expanded tags: ${JSON.stringify(selectedTags)}`);

    let reduced = allTags.categories.reduce((reduced, category) => {
        let subcategories = category.subCategories.reduce((reduced, subcategory) => {
            let tags = subcategory.tags.reduce((reduced, tag) => {
                if (selectedTags[category.id] !== undefined
                    && selectedTags[category.id][subcategory.id] !== undefined
                    && selectedTags[category.id][subcategory.id].includes(tag.id)) {
                    reduced.push(tag);
                    console.log('Added a tag!  new reduced: ' + JSON.stringify(reduced));
                }
                return reduced;
            }, []);

            if (tags !== undefined && tags.length > 0) {
                reduced.push({ ...subcategory, tags: tags });
                console.log('Added a subcategory!  new reduced: ' + JSON.stringify(reduced));
            }
            return reduced;
        }, []);
        if (subcategories && subcategories.length > 0) {
            reduced.push({ ...category, subCategories: subcategories });
        }
        return reduced;
    }, []);

    console.log(`expanded tags: ${JSON.stringify(reduced)}`);

    return { categories: reduced };
};


export const convertTagsToKeyed = (tags) => {

    console.log(`pre-reduced tags: ${JSON.stringify(tags)}`);

    if (tags.categories === undefined) return {};

    let reduced = tags.categories.reduce((reduced, category) => {
        reduced[category.id] = category.subCategories.reduce((reduced, subcategory) => {
            reduced[subcategory.id] = subcategory.tags.reduce((reduced, tag) => {
                reduced.push(tag.id);
                return reduced;
            }, [])
            return reduced;
        }, {});
        return reduced;
    }, {});

    console.log(`reduced tags: ${JSON.stringify(reduced)}`);

    return reduced;

};