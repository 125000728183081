import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import { useCurrentUser } from 'providers/user/UserContext';


export default function UnauthRoute({ children, ...rest }) {

    const { data } = useCurrentUser();

    const render = ({location}) => {
        if (data && data.name !== undefined && data.name.first !== undefined && data.name.first.length > 0 && (data.type ==='athlete' || data.verified === true)) {
            return (
                <Redirect
                    to={{
                        pathname: "/dashboard",
                        state: {from: location}
                    }}
                />
            )

        } else if (data && data.name !== undefined && data.name.first !== undefined && data.name.first.length > 0) {
            return (
                <Redirect
                    to={{
                        pathname: "/gameplan",
                        state: {from: location}
                    }}
                />
            )
        } else if (data) {
            return (
                <Redirect
                    to={{
                        pathname: "/profile",
                        state: {from: location}
                    }}
                />
            )
        } else {
            return (
                children
            )
        }
    };

    return (
        <Route
            {...rest}
            render={render}
        />
    );
};