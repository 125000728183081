import 'array-flat-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';
import Firebase from 'providers/firebase/Firebase';
import { FirebaseContext } from 'providers/firebase/FirebaseContext';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

require('object.values/auto');

function noop() {}

// if (process.env.NODE_ENV !== 'development') {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
// }


Sentry.init({dsn: "https://c6ff9f6878034ed29b2934e631ca5527@o413352.ingest.sentry.io/5299225"});

ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={new Firebase()}>
      <App />
    </FirebaseContext.Provider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
