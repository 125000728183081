import React from 'react';

import { Redirect, Route } from 'react-router-dom';

import { useCurrentUser } from 'providers/user/UserContext';

export default function VerifiedRoute({ children, ...rest }) {

    const { data } = useCurrentUser();

    const render = ({location}) => {

        if (data && (data.type === 'athlete' || (data.type === 'employer' && data.verified === true))) {
            return (
                children
            )
        } else if (data) {
            return (
                <Redirect
                    to={{
                        pathname: "/gameplan",
                        state: {from: location}
                    }}
                />
            )
        } else {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: {from: location}
                    }}
                />
            )
        }
    };

    return (
        <Route
            {...rest}
            render={render}
        />
    );
};